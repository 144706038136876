import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {GridActionsCellItem, GridRowId, GridRowParams} from '@mui/x-data-grid'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPen,
    faTrash,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons'

//-- TABLE AND TABLE DEFINITIONS
import {Table} from 'components/table/Table'
import {locationsColumnDef} from 'metadata/TableDefinitions'

//-- PAGE COMPONENTS
import {PrimaryButton} from 'components/button/Button'
import {CommonFilter} from 'components/commonFilter/CommonFilter'
import {Growl} from 'components/growl/Growl'
import {EmptyState} from 'components/emptyState/EmptyState'

//-- SUB PAGES
import {Location} from './Location'

//-- UTILITIES AND TYPES
import {applyView, getViewId, growl, randomNumber} from 'functions/CommonUtils'
import {GrowlType, ILocation, SELECT_ONE} from 'types'
import {LoadingState} from 'components/loadingState/LoadingState'

//-- API AND ACTIONS
import {
    apiListLocations,
    apiDeleteLocation,
} from 'api'

//-- STYLES
import './Locations.scss'


/***
 * LOCAL CONSTANTS
 */
const EDIT_VIEW_ID= 'location'
const NEW_VIEW_ID= 'newLocation'


/***
 * INTERFACES
 */
interface ILocationsProps {
    id?: string,
    setPageSubTitle: Function
}


/**
 * Functional Component - Locations
 * @param id
 * @param setPageSubTitle
 * @constructor
 */
export const Locations: React.FC<ILocationsProps> = ({
    id = 'locationsPage',
    setPageSubTitle,
}: ILocationsProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [locationId, setLocationId] = useState<number|string|undefined>(getViewId(EDIT_VIEW_ID))
    const [data, setData] = useState<ILocation[]>([])
    const [loaded, setLoaded] = useState(false)
    const [location, setLocation] = useState<ILocation|undefined>()
    const [associationFilter, setAssociationFilter] = useState<boolean>()
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())

    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        setLoaded(false)
        if (accessToken) {
            if (associationFilter !== undefined) {
                apiListLocations(accessToken, setData, setLoaded, associationFilter)
            } else {
                apiListLocations(accessToken, setData, setLoaded)
            }
        }
    }, [associationFilter, accessToken])

    useEffect(() => {
        if (getViewId(NEW_VIEW_ID)) {
            initNewLocation()
        }
    }, [getViewId(NEW_VIEW_ID)])

    useEffect(() => {
        if (data && data.length && locationId) {
            const value: ILocation | undefined = data.find((location) => location.id === locationId)
            setLocation(value)
            setPageSubTitle(`Location Editor`)
        }
    }, [locationId, data])


    /***
     * UTILITY FUNCTIONS
     */
    const initNewLocation = () => {
        const initLocation: ILocation = {
            name: '',
        }
        setLocation(initLocation)
        applyView(NEW_VIEW_ID, 'true')
        setPageSubTitle(`New Location`)
    }

    const handleResetEditor = () => {
        setLocation(undefined)
        setLocationId(undefined)
        setPageSubTitle(undefined)
        applyView(EDIT_VIEW_ID, undefined)
        applyView(NEW_VIEW_ID, undefined)
    }

    const handleSave = () => {
        growl(`${location ? location.name : 'Location'} Saved Successfully`, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        handleResetEditor()
    }


    /***
     * CALLBACK FUNCTIONS
     */
    const editLocation = React.useCallback(
        (location: ILocation) => () => {
            if (location?.id) {
                setLocationId(location.id)
                setLocation(location)
                applyView(EDIT_VIEW_ID, location?.id)
                applyView(NEW_VIEW_ID, undefined)
            } else {
                setTimeout(() => {
                    growl('Unable to edit selected location', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
                }, 100)
            }
        },
        [data],
    )

    const deleteLocation = React.useCallback(
        (id: GridRowId) => () => {
            apiDeleteLocation(accessToken, id, setData, data, 'Location Successfully Deleted', setGrowlMessage, setGrowlType, setGrowlInstance)
        },
        [data],
    )


    /***
     * ICONS
     */
    const editActionIcon = (locationName: string) => <FontAwesomeIcon icon={faPen} title={`Edit ${locationName}`} className='datagrid-action-icon' />
    const deleteActionIcon = (locationName: string) => <FontAwesomeIcon icon={faTrash} title={`Delete ${locationName}`} className='datagrid-action-icon' />


    /***
     * DATAGRID COLUMNS
     */
    const columns= [...locationsColumnDef,
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                    icon={editActionIcon(params.row.name)}
                    label='Edit'
                    onClick={editLocation(params.row)}
                />,
                <GridActionsCellItem
                    icon={deleteActionIcon(params.row.name)}
                    label='Delete'
                    disabled={params.row.associatedProperties > 0}
                    onClick={deleteLocation(params.id)}
                />,
            ]
        }
    ]

    return(
        <>
            {loaded  ? (
                <>
                    {location ? (
                       <>
                           <Location id='locationPage' location={location} setData={setData} data={data}
                                     handleBack={handleResetEditor} handleSave={handleSave} />
                       </>
                    ) : locationId === undefined && (
                        <>
                            {data?.length || associationFilter !== undefined ? (
                                <>
                                    <div id={`${id}_header`} className='base_header'>
                                        <CommonFilter id={`${id}_locationsFilter`} title='Filter Locations'
                                                      associationFilter={associationFilter} setAssociationFilter={setAssociationFilter}
                                        />
                                        <div className='controls'>
                                            <PrimaryButton id={`${id}_new`} onClick={() => initNewLocation()}
                                                           startIcon={<FontAwesomeIcon icon={faLocationDot} />}
                                                           label='New Location' />
                                        </div>
                                    </div>
                                    <Table id={`${id}_locations`} columns={columns} rows={data} />
                                </>
                            ) : (
                                <EmptyState id={'emptyState'} icon={faLocationDot}
                                            caption={'No locations found'}
                                            text={'Click the button below to add a new property location.'}
                                            action={initNewLocation}
                                            actionLabel={'Add New Location'}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <LoadingState id={`${id}_loading`}/>
            )}
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    )
}