import moment from 'moment'
import {GrowlType} from 'types'

/** [ VIEW UTILITIES ] ************************************************************************************************/
export type view = 'property' | 'newProperty' | 'propertyPhotos' | 'propertyVideos' |
    'location' | 'newLocation' |
    'feature' | 'newFeature' |
    'contact' | 'newContact' |
    'viewing' | 'newViewing'
export const applyView = (view: view, value: number|string|undefined) => {
    if (value) {
        sessionStorage.setItem(view, value.toString())
    } else {
        sessionStorage.removeItem(view)
    }
}

export const getViewId = (view: view): number|string|undefined => {
    const value = sessionStorage.getItem(view)
    if (value !== null && value.length) {
        if (!isNaN(+value)) {
            return toNumber(value, -1)
        }
        return value
    }
    return undefined
}

/** [ CURRENCY UTILITIES ] ********************************************************************************************/
const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}

const currencyFormatter = new Intl.NumberFormat('en-US', currencyOptions)

export const parseLocaleCurrency = (stringNumber: string, locale: string) => {
    const partValues = currencyFormatter.formatToParts(0).map(p => p.value)
    const currency = partValues?.length ? partValues[0] : '$'
    const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + currency, 'g'), '')
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
}

export const formatCurrency = (value: number|string):string => {
    return currencyFormatter.format(Number(value))
}


/** [ MUMERIC UTILITIES ] *********************************************************************************************/
export const toNumber = (value: string, defaultValue: number|undefined):number => {
    return isNaN(+value) ? (defaultValue || 0) : Number(value)
}

export const randomNumber = (): number => {
    return Math.floor(Math.random() * 1000)
}


/** [ DATE UTILITIES ] ************************************************************************************************/
export const isDateBefore = (date1: Date, date2: Date): boolean => {
    return date1.getTime() < date2.getTime()
}

export const roundToNearestHour = (): Date => {
    let date = moment().toDate()
    date.setMinutes(0, 0, 0)
    date.setHours(date.getHours() + 1)
    return moment(Math.ceil(date.getTime() / (60 * 60 * 1000)) * (60 * 60 * 1000)).toDate()
}

export const formatDate = (dateStr: string, variant?: 'long' | 'medium' | 'short') => {
    const longFormat: string = 'llll'
    const mediumFormat: string = 'LLL'
    const shortFormat: string = 'L LT'

    let datetime = moment(dateStr)
    switch(variant) {
        case 'long': return datetime.format(longFormat)
        case 'medium': return datetime.format(mediumFormat)
        case 'short': return datetime.format(shortFormat)
    }
    return datetime.format(shortFormat)
}


/** [ UI UTILITIES ] **************************************************************************************************/
export const growl = (message?:string, type?: GrowlType, setGrowlMessage?: Function, setGrowlType?: Function, setGrowlInstance?: Function) => {
    if (message && type && setGrowlMessage && setGrowlType && setGrowlInstance) {
        setGrowlMessage(message)
        setGrowlType(type)
        setGrowlInstance(randomNumber())
    }
}


/** [ ENUM UTILITIES ] ************************************************************************************************/
type Entries<T extends object> = { [K in keyof T]: [K, T[K]] }[keyof T]

const reverseEnum = <E extends Record<keyof E, string | number>>(
    e: E
): { [K in E[keyof E]]: Extract<Entries<E>, [any, K]>[0] } => {
    const ret: Partial<Record<E[keyof E], keyof E>> = {}
    for (const k of Object.keys(e) as (keyof E)[]) {
        ret[e[k]] = k
    }
    return ret as any
}

export const reversedEnum = <E extends Record<keyof E, string | number>>(e: E) => {
    return Object.assign(reverseEnum(e), e)
}