import React from 'react'

//-- PAGE COMPONENTS
import {SecondaryButton} from '../button/Button'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
    faGears,
    faCirclePlus,
} from '@fortawesome/free-solid-svg-icons'

//-- STYLES
import './EmptyState.scss'

interface IEmptyStateProps {
    id: string,
    icon?: IconProp,
    caption?: string,
    text?: string,
    action?: Function,
    actionLabel?: string,
}

export const EmptyState: React.FC<IEmptyStateProps> = ({
    id,
    icon = faGears,
    caption = 'No data found',
    text = '',
    action,
    actionLabel = 'Add Data',
}: IEmptyStateProps) => {
    return (
        <div className={'wrapper'}>
            <div className={'container'}>
                <div className={'iconWrapper'}>
                    <FontAwesomeIcon icon={icon} fixedWidth className={'icon'} />
                </div>
                <span className={'text'}>
                    {caption}
                    <span className={'subtext'}>
                        {text}
                    </span>

                    {action && (
                        <SecondaryButton id={`${id}_new`} onClick={action}
                                         startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                                         label={actionLabel} />
                    )}
                </span>
            </div>
        </div>
    )
}