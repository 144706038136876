import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPowerOff, faUser, faGear} from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { LeftNavBar } from 'layout/leftNavBar/LeftNavBar'
import TopBar from 'layout/topBar/TopBar'
import { SearchBox } from 'components/input/SearchBox'
// TODO: REMOVE Dropdown component from the codebase
// import { Dropdown } from 'components/dropdown/Dropdown'
import { MenuItemType, getMenuItemIcon } from 'enums/Types'
import * as nav from 'functions/NavFunctions'
// import { Dashboard } from 'pages/dashboard/Dashboard'
import { Properties } from 'pages/properties/Properties'
import { Locations } from 'pages/locations/Locations'
import { Features } from 'pages/features/Features'
import { Contacts } from 'pages/contacts/Contacts'
import { Viewings } from 'pages/viewings/Viewings'
import { Search } from 'pages/search/Search'
import {Popover, Stack, Box} from '@mui/material'
import {LoadingState} from 'components/loadingState/LoadingState'
import {useAuth0} from '@auth0/auth0-react'
import {
    getActivePage, handleNavDefault,
    handleNavSearch,
    isLoginPageActive,
    isPageActive,
    isValidPage
} from 'functions/NavFunctions'
import 'moment-timezone'
import './styles/App.scss'

const App = () => {
    //-- Local Variables
    const {loginWithRedirect, logout, isAuthenticated, isLoading, user} = useAuth0()
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemType>(MenuItemType[getActivePage()?.toUpperCase() as keyof typeof MenuItemType] || MenuItemType.PROPERTIES)
    const [containerHeight, setContainerHeight] = useState(0)
    const [pageTitle, setPageTitle] = useState('')
    const [pageSubTitle, setPageSubTitle] = useState('')
    const [searchText, setSearchText] = useState<string|undefined>()
    const [isResultsLoaded, setIsResultsLoaded] = useState(true)
    const [userMenuPopoverAnchorEl, setUserMenuPopoverAnchorEl] = React.useState<HTMLDivElement | null>(null)

    const appBodyRef = useRef<HTMLDivElement>(null)
    const pageHeaderRef = useRef<HTMLDivElement>(null)
    const showUserMenuPopover = Boolean(userMenuPopoverAnchorEl)

    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) {
                loginWithRedirect()
            } else {
                setTimeout(() => {
                    if (!getActivePage()) {
                        handleNavDefault()
                    }
                    updateContainerHeight()
                    window.addEventListener('resize', updateContainerHeight)
                    return () => window.removeEventListener('resize', updateContainerHeight)
                }, 1)
            }
        }
    }, [isLoading])

    useEffect(() => {
        const currPage: string|null = getActivePage()
        const showPage: boolean = isAuthenticated && !isLoading && !isLoginPageActive()
        if (showPage && currPage) {
            if (isValidPage(currPage)) {
                setSelectedMenuItem(MenuItemType[currPage.toUpperCase() as keyof typeof MenuItemType])
            } else {
                console.error('Unknown menu item')
            }
        }
    }, [getActivePage()])

    useEffect(() => {
        if (selectedMenuItem === MenuItemType.SEARCH || isPageActive(MenuItemType.SEARCH)) {
            setPageTitle(`Search Results${searchText ? ` for '${searchText}'` : ''}`)
        } else {
            setPageTitle(`Manage ${selectedMenuItem}${pageSubTitle ? ` - ${pageSubTitle}` : ''}`)
        }
    }, [selectedMenuItem, pageSubTitle, searchText])

    const updateContainerHeight = () => {
        setContainerHeight(appBodyRef?.current?.clientHeight! - pageHeaderRef?.current?.clientHeight!)
    }

    const applySearch = (searchText: string): void => {
        setIsResultsLoaded(false)
        setSearchText(undefined)
        setTimeout(() => {
            setSearchText(searchText)
        })
        setPageTitle(`Search Results for '${searchText}'`)
        handleNavSearch()
    }

    const handleClear = (): void => {
        setSearchText(undefined)
    }

    const handlePostNavigation = (): void => {
        setPageSubTitle('')
        handleClear()
    }

    const handleShowUserMenuPopover = (event: React.MouseEvent<HTMLDivElement>) => {
        setUserMenuPopoverAnchorEl(event.currentTarget)
    }

    const handleCloseUserMenuPopover = () => {
        setUserMenuPopoverAnchorEl(null)
    }

    const handleLogout = (): void => {
        window.sessionStorage.clear()
        logout()
    }

    return (
        <div className="app-root">
            {isAuthenticated ? (
                <>
                    <LeftNavBar id='leftNavBar' handleMenuNav={nav.handleMenuNav} selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} onNavigate={handlePostNavigation} />
                    <TopBar id='topBar'>
                        <TopBar.Left>
                            <SearchBox id='searchAll' searchText={searchText} handleSubmit={applySearch} handleClear={handleClear} />
                        </TopBar.Left>
                        <TopBar.Right>
                            <div className='user-menu-anchor' onClick={handleShowUserMenuPopover} >
                                Welcome <span className='user-menu-name'>{user?.name ? user.name : ''}</span>
                                <FontAwesomeIcon icon={faCircleUser} fixedWidth size={'xl'} />
                            </div>
                            <Popover
                                id={'userMenu'}
                                open={showUserMenuPopover}
                                onClose={handleCloseUserMenuPopover}
                                anchorEl={userMenuPopoverAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className={'user_menu'}
                            >
                                <Box sx={{ minWidth: '10rem' }}>
                                    <Stack spacing={0.5}>
                                        <div onClick={() => {}} className={'popover_menu_item'}>
                                            <FontAwesomeIcon icon={faUser} fixedWidth size={'lg'} />
                                            <span className={'label'}>Profile</span>
                                        </div>
                                        <div onClick={() => {}} className={'popover_menu_item'}>
                                            <FontAwesomeIcon icon={faGear} fixedWidth size={'lg'} />
                                            <span className={'label'}>Settings</span>
                                        </div>
                                        <div onClick={() => handleLogout()} className={'popover_menu_item separator'}>
                                            <FontAwesomeIcon icon={faPowerOff} fixedWidth size={'lg'} />
                                            <span className={'label'}>Logout</span>
                                        </div>
                                    </Stack>
                                </Box>
                            </Popover>
                        </TopBar.Right>
                    </TopBar>
                    <div className='app-body' ref={appBodyRef}>
                        <div className={'page-header'} ref={pageHeaderRef}>
                            <div className={'page-title-wrapper'}>
                                <FontAwesomeIcon icon={getMenuItemIcon(selectedMenuItem)} fixedWidth size={'lg'} />
                                <div className={'page-title'} title={isPageActive(MenuItemType.SEARCH) ? searchText : ''}>{pageTitle}</div>
                            </div>
                        </div>

                        <div className='page-body' style={{overflow: 'auto', height: (containerHeight ? containerHeight - 35 : 600)}}>
                            {/*{isPageActive(MenuItemType.DASHBOARD) && (*/}
                            {/*    <Dashboard id='dashboardPage' />*/}
                            {/*)}*/}

                            {isPageActive(MenuItemType.PROPERTIES) && (
                                <Properties id='propertiesPage' setPageSubTitle={setPageSubTitle} />
                            )}

                            {isPageActive(MenuItemType.LOCATIONS) && (
                                <Locations id='locationsPage' setPageSubTitle={setPageSubTitle} />
                            )}

                            {isPageActive(MenuItemType.FEATURES) && (
                                <Features id='featuresPage' setPageSubTitle={setPageSubTitle} />
                            )}

                            {isPageActive(MenuItemType.CONTACTS) && (
                                <Contacts id='contactsPage' setPageSubTitle={setPageSubTitle} />
                            )}

                            {isPageActive(MenuItemType.VIEWINGS) && (
                                <Viewings id='viewingsPage' setPageSubTitle={setPageSubTitle} />
                            )}

                            {isPageActive(MenuItemType.SEARCH) && (
                                <Search id={'searchResultsPage'} searchText={searchText} setSelectedMenuItem={setSelectedMenuItem} isResultsLoaded={isResultsLoaded} setIsResultsLoaded={setIsResultsLoaded} searchIcon={getMenuItemIcon(selectedMenuItem)}/>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className={'loading-spinner'}>
                    <LoadingState id={'loading_spinner'}/>
                </div>
            )}
        </div>
    )
}

export default App;
