import {ILocation} from './location'
import {ICoordinates} from './coordinates'
import {IFeature} from './feature'
import {IContact} from './contact'
import {IPhoto} from './photo'
import {IVideo} from './video'

export interface IProperty {
    id?: number,
    propertyName: string,
    propertyOwner: IContact,
    bedrooms?: number,
    bathrooms?: number,
    floors?: number,
    acreage?: number,
    location: ILocation,
    coordinates: ICoordinates,
    features?: IFeature[],
    propertyType: string,
    furnishedType?: string,
    status: string,
    featured?: boolean,
    price: number,
    priceFreq?: string,
    caption: string,
    description: string,
    photos: IPhoto[],
    videos: IVideo[],
}

export enum FurnishedType {
    NOT_FURNISHED = 'Not Furnished',
    SEMI_FURNISHED = 'Semi Furnished',
    FULLY_FURNISHED = 'Fully Furnished',
}

export enum PriceFreq {
    SQFT = '/ Sq. Foot',
    NIGHTLY = 'Nightly',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
}

export enum PropertyStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    RENTED = 'Rented',
    SOLD = 'Sold',
}

export enum PropertyType {
    RESIDENTIAL_RENTAL = 'Rental Property',
    COMMERCIAL = 'Commercial Lease',
    SHORT_TERM_RENTAL = 'Short Term Rental',
    RESIDENTIAL_SALE = 'Residential Sale',
    COMMERCIAL_SALE = 'Commercial Sale',
    LAND_SALE = 'Land Sale',
}

export const RentalPropertyTypes: PropertyType[] = [
    PropertyType.RESIDENTIAL_RENTAL,
    PropertyType.SHORT_TERM_RENTAL,
    PropertyType.COMMERCIAL,
]

export const SalePropertyTypes: PropertyType[] = [
    PropertyType.RESIDENTIAL_SALE,
    PropertyType.COMMERCIAL_SALE,
    PropertyType.LAND_SALE,
]

export const ResidentialPropertyTypes: PropertyType[] = [
    PropertyType.RESIDENTIAL_RENTAL,
    PropertyType.SHORT_TERM_RENTAL,
    PropertyType.RESIDENTIAL_SALE,
]

export const CommercialPropertyTypes: PropertyType[] = [
    PropertyType.COMMERCIAL,
    PropertyType.COMMERCIAL_SALE,
]

export const isRentalProperty = (propertyType: string) => {
    const rentalPropertyTypeKeys = Object.entries(PropertyType)
        .filter(([key, value]) => RentalPropertyTypes.includes(value))
        .map(([key]) => key)
    return rentalPropertyTypeKeys.includes(propertyType)
}

export const isSaleProperty = (propertyType: string) => {
    const salePropertyTypeKeys = Object.entries(PropertyType)
        .filter(([key, value]) => SalePropertyTypes.includes(value))
        .map(([key]) => key)
    return salePropertyTypeKeys.includes(propertyType)
}

export const isResidentialProperty = (propertyType: string, saleType?: boolean) => {
    const residentialPropertyTypeKeys = Object.entries(PropertyType)
        .filter(([key, value]) => ResidentialPropertyTypes.includes(value))
        .map(([key]) => key)
    let match
    if (saleType !== undefined) {
        if (saleType) {
            match = isSaleProperty(propertyType)
        } else {
            match = isRentalProperty(propertyType)
        }
    } else {
        match = true
    }
    return residentialPropertyTypeKeys.includes(propertyType) && match
}

export const isCommercialProperty = (propertyType: string, saleType?: boolean) => {
    const commercialPropertyTypeKeys = Object.entries(PropertyType)
        .filter(([key, value]) => CommercialPropertyTypes.includes(value))
        .map(([key]) => key)
    let match
    if (saleType !== undefined) {
        if (saleType) {
            match = isSaleProperty(propertyType)
        } else {
            match = isRentalProperty(propertyType)
        }
    } else {
        match = true
    }
    return commercialPropertyTypeKeys.includes(propertyType) && match
}

export const isLandSale = (propertyTypeKey: string) => {
    const propertyType: PropertyType = PropertyType[propertyTypeKey as keyof typeof PropertyType]
    return PropertyType.LAND_SALE === propertyType
}