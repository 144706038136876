import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPen,
} from '@fortawesome/free-solid-svg-icons'
import { MenuItemType, getMenuItemIcon } from 'enums/Types'
import {getViewId} from 'functions/CommonUtils'
import './AppMenu.scss'


interface IAppMenuProps {
    id: string,
    menuItems: MenuItemType[],
    selectedMenuItem: MenuItemType,
    setSelectedMenuItem: Function,
    handleMenuNav: Function,
    onNavigate: Function,
}

export const AppMenu: React.FC<IAppMenuProps> = ({
    id,
    menuItems,
    selectedMenuItem,
    setSelectedMenuItem,
    handleMenuNav,
    onNavigate,
}: IAppMenuProps) => {

    const getIsEditing = (menuItem: MenuItemType): boolean => {
        switch(menuItem) {
            case MenuItemType.PROPERTIES: return (getViewId('property') ?? getViewId('newProperty') ?? getViewId('propertyPhotos') ?? getViewId('propertyVideos')) !== undefined
            case MenuItemType.LOCATIONS: return (getViewId('location') ?? getViewId('newLocation')) !== undefined
            case MenuItemType.FEATURES: return (getViewId('feature') ?? getViewId('newFeature')) !== undefined
            case MenuItemType.CONTACTS: return (getViewId('contact') ?? getViewId('newContact')) !== undefined
            case MenuItemType.VIEWINGS: return (getViewId('viewing') ?? getViewId('newViewing')) !== undefined
            default: return false
        }
    }

    return (
        <nav id={id} className='app-menu'>
            {menuItems?.length && (
                <ul>
                    {menuItems?.map((item) => {
                        return (
                            <li id={item} key={item} onClick={() => {
                                if (item !== selectedMenuItem) {
                                    handleMenuNav(item, selectedMenuItem, setSelectedMenuItem)
                                    onNavigate()
                                }
                            }} className={`${item === selectedMenuItem && 'app-menu-item-selected'}`} >
                                <FontAwesomeIcon icon={getMenuItemIcon(item)} fixedWidth size='lg' />
                                <span className='menu-item-name'>{item} <span className='menu-item-edit'>{getIsEditing(item) && <FontAwesomeIcon icon={faPen} size='sm' />}</span></span>
                            </li>
                        )
                    })}
                </ul>
            )}
        </nav>
    )
}