import {useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {AUTH0_AUDIENCE, AUTH0_SCOPES} from 'types'

export const useAccessToken = (): [string|undefined] => {
    const {isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup} = useAuth0()
    const [accessToken, setAccessToken] = useState<string|undefined>()

    useEffect(() => {
        if (isAuthenticated) {
            try {
                getAccessTokenSilently({
                    authorizationParams: {
                        audience: AUTH0_AUDIENCE,
                        scope: AUTH0_SCOPES,
                    }
                }).then((accessToken) => {
                    setAccessToken(accessToken)
                })
            } catch (error) {
                if (error === 'consent_required') {
                    getAccessTokenWithPopup({
                        authorizationParams: {
                            audience: AUTH0_AUDIENCE,
                            scope: AUTH0_SCOPES,
                        }
                    }).then((accessToken) => {
                        setAccessToken(accessToken)
                    })
                }
            }
        }
    }, [isAuthenticated])

    return [accessToken]
}