import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {GridActionsCellItem, GridRowId, GridRowParams} from '@mui/x-data-grid'
import {ButtonGroup} from '@mui/material'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPen,
    faCalendarXmark,
    faTrash,
    faCalendarDays,
    faHouseUser,
    faCircleUser,
    faFilter,
} from '@fortawesome/free-solid-svg-icons'

//-- TABLE AND TABLE DEFINITIONS
import {Table} from 'components/table/Table'
import {viewingsColumnDef} from 'metadata/TableDefinitions'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import {EmptyState} from 'components/emptyState/EmptyState'
import {LoadingState} from 'components/loadingState/LoadingState'
import { Growl } from 'components/growl/Growl'

//-- SUB PAGES
import {Viewing} from './Viewing'

//-- UTILITIES AND TYPES
import {applyView, getViewId, growl, randomNumber, roundToNearestHour, reversedEnum} from 'functions/CommonUtils'
import {GrowlType, IContact, IProperty, IViewing, SELECT_ONE, ViewingStatus} from 'types'

//-- API AND ACTIONS
import {
    apiListViewings,
    apiDeleteViewing, apiNotifyViewing,
} from 'api'
import {applyViewingCancellation} from './actions/ViewingActions'

//-- STYLES
import './Viewings.scss'


/***
 * LOCAL CONSTANTS
 */
const EDIT_VIEW_ID= 'viewing'
const NEW_VIEW_ID= 'newViewing'


/***
 * INTERFACES
 */
interface IViewingsProps {
    id?: string,
    setPageSubTitle: Function
}


/**
 * Functional Component - Viewings
 * @param id
 * @param setPageSubTitle
 * @constructor
 */
export const Viewings: React.FC<IViewingsProps> = ({
    id = 'viewingsPage',
    setPageSubTitle,
}: IViewingsProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [viewingId, setViewingId] = useState<number|string|undefined>(getViewId(EDIT_VIEW_ID))
    const [data, setData] = useState<IViewing[]>([])
    const [statusFilter, setStatusFilter] = useState<ViewingStatus|undefined>()
    const [loaded, setLoaded] = useState(false)
    const [viewing, setViewing] = useState<IViewing|undefined>()
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        setLoaded(false)
        if (accessToken) {
            if (statusFilter !== undefined) {
                apiListViewings(accessToken, setData, setLoaded, statusFilter)
            } else {
                apiListViewings(accessToken, setData, setLoaded)
            }
        }
    }, [statusFilter, accessToken])

    useEffect(() => {
        if (getViewId(NEW_VIEW_ID)) {
            initNewViewing()
        }
    }, [getViewId(NEW_VIEW_ID)])

    useEffect(() => {
        if (data && data.length) {
            if (viewingId) {
                const value: IViewing | undefined = data.find((viewing) => viewing.id === viewingId)
                setViewing(value)
                setPageSubTitle(`Viewing Editor`)
            }
        }
    }, [viewingId, data])


    /***
     * UTILITY FUNCTIONS
     */
    const initNewViewing = () => {
        const initViewing: IViewing = {
            client: {id: -1, name: SELECT_ONE} as IContact,
            schedule: roundToNearestHour().toISOString(),
            status: reversedEnum(ViewingStatus)[ViewingStatus.SCHEDULED],
            property: {id: -1, propertyName: SELECT_ONE} as IProperty,
        }
        setViewing(initViewing)
        applyView(NEW_VIEW_ID,'true')
        setPageSubTitle(`New Viewing`)
    }

    const handleResetEditor = () => {
        setViewing(undefined)
        setViewingId(undefined)
        setPageSubTitle(undefined)
        applyView(EDIT_VIEW_ID, undefined)
        applyView(NEW_VIEW_ID,undefined)
    }

    const handleSave = () => {
        growl(`${viewing?.property?.propertyName ? viewing.property.propertyName : 'Property'} Viewing Saved Successfully`, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        handleResetEditor()
    }


    /***
     * CALLBACK FUNCTIONS
     */
    const editViewing = React.useCallback(
        (viewing: IViewing) => () => {
            if (viewing?.id) {
                setViewingId(viewing.id)
                setViewing(viewing)
                applyView(EDIT_VIEW_ID, viewing?.id)
                applyView(NEW_VIEW_ID, undefined)
            } else {
                setTimeout(() => {
                    growl(`Unable to edit selected viewing`, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
                }, 100)
            }
        },
        [data],
    )

    const cancelViewing = React.useCallback(
        (viewing: IViewing) => () => {
            if (viewing?.id) {
                viewing.status = reversedEnum(ViewingStatus)[ViewingStatus.CANCELLED]
                viewing.cancelled = true
                const payload: any = {
                    ...viewing
                }
                applyViewingCancellation(accessToken, setData, data, payload, statusFilter, setLoaded, 'Property Viewing Cancelled', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        },
        [data],
    )

    const deleteViewing = React.useCallback(
        (id: GridRowId) => () => {
            apiDeleteViewing(accessToken, id, setData, data,'Property Viewing Successfully Deleted', setGrowlMessage, setGrowlType, setGrowlInstance)
        },
        [data],
    )

    const sendViewingNotification = React.useCallback(
        (id: GridRowId, contact: IContact, client: boolean) => () => {
            if (contact?.email1 || contact?.email2) {
                apiNotifyViewing(accessToken, id, setData, data, client, `${client ? 'Client' : 'Owner'} Notification Sent`, setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                growl(`Unable to Send Notification - ${client ? 'Client' : 'Owner'} Email Address Missing`, 'warning', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        },
        [data],
    )


    /***
     * ICONS
     */
    const editActionIcon = (viewingName: string) => <FontAwesomeIcon icon={faPen} title={`Edit ${viewingName} Viewing`} className='datagrid-action-icon' />
    const cancelActionIcon = (viewingName: string) => <FontAwesomeIcon icon={faCalendarXmark} title={`Cancel ${viewingName} Viewing`} className='datagrid-action-icon' />
    const deleteActionIcon = (viewingName: string) => <FontAwesomeIcon icon={faTrash} title={`Delete ${viewingName} Viewing`} className='datagrid-action-icon' />
    const notifyClientActionIcon = () => <FontAwesomeIcon icon={faCircleUser} fixedWidth className='datagrid-action-icon' />
    const notifyOwnerActionIcon = () => <FontAwesomeIcon icon={faHouseUser} fixedWidth className='datagrid-action-icon' />


    /***
     * DATAGRID COLUMNS
     */
    const columns= [...viewingsColumnDef,
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                    icon={editActionIcon(params.row.property.propertyName)}
                    label='Edit'
                    onClick={editViewing(params.row)}
                    disabled={params.row.cancelled}
                />,
                <GridActionsCellItem
                    icon={cancelActionIcon(params.row.property.propertyName)}
                    label='Cancel'
                    onClick={cancelViewing(params.row)}
                    disabled={params.row.cancelled}
                />,
                <GridActionsCellItem
                    icon={deleteActionIcon(params.row.property.propertyName)}
                    label='Delete'
                    onClick={deleteViewing(params.row.id)}
                />,
                <GridActionsCellItem
                    icon={notifyClientActionIcon()}
                    label={`Send Schedule to Client`}
                    onClick={sendViewingNotification(params.row.id, params.row.client, true)}
                    disabled={params.row.cancelled}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={notifyOwnerActionIcon()}
                    label={`Notify Owner of Viewing`}
                    onClick={sendViewingNotification(params.row.id, params.row.property.propertyOwner, false)}
                    disabled={params.row.cancelled}
                    showInMenu
                />,
            ]
        }
    ]

    return(
        <>
            {loaded  ? (
                <>
                    {viewing ? (
                        <>
                            <Viewing    id='viewingPage' viewing={viewing} setData={setData} data={data}
                                        handleBack={handleResetEditor} handleSave={handleSave} />
                        </>
                    ) : viewingId === undefined && (
                        <>
                            {data?.length || statusFilter !== undefined ? (
                                <>
                                    <div id={`${id}_header`} className='base_header'>
                                        <div id={id} className='base_filters'>
                                            <div className='filters_label'>
                                                <FontAwesomeIcon icon={faFilter} fixedWidth={true}/>
                                                Filter Viewings:
                                            </div>
                                            <ButtonGroup className='filters_buttongroup'>
                                                <SecondaryButton id={`${id}_filterAll`} onClick={() => setStatusFilter(undefined)}
                                                                 label='All'
                                                                 className={statusFilter === undefined ? 'filter_selected' : 'filter_unselected'}/>

                                                <SecondaryButton id={`${id}_filterAssociated`} onClick={() => setStatusFilter(ViewingStatus.SCHEDULED)}
                                                                 label={ViewingStatus.SCHEDULED.valueOf()}
                                                                 className={statusFilter === ViewingStatus.SCHEDULED ? 'filter_selected' : 'filter_unselected'}
                                                />

                                                <SecondaryButton id={`${id}_filterAssociated`} onClick={() => setStatusFilter(ViewingStatus.IN_PROGRESS)}
                                                                 label={ViewingStatus.IN_PROGRESS.valueOf()}
                                                                 className={statusFilter === ViewingStatus.IN_PROGRESS ? 'filter_selected' : 'filter_unselected'}
                                                />

                                                <SecondaryButton id={`${id}_filterAssociated`} onClick={() => setStatusFilter(ViewingStatus.COMPLETED)}
                                                                 label={ViewingStatus.COMPLETED.valueOf()}
                                                                 className={statusFilter === ViewingStatus.COMPLETED ? 'filter_selected' : 'filter_unselected'}
                                                />

                                                <SecondaryButton id={`${id}_filterAssociated`} onClick={() => setStatusFilter(ViewingStatus.CANCELLED)}
                                                                 label={ViewingStatus.CANCELLED.valueOf()}
                                                                 className={statusFilter === ViewingStatus.CANCELLED ? 'filter_selected' : 'filter_unselected'}
                                                />
                                            </ButtonGroup>
                                        </div>
                                        <div className='controls'>
                                            <PrimaryButton  id={`${id}_new`} onClick={() => initNewViewing()}
                                                            startIcon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                            label='Schedule Viewing' />
                                        </div>
                                    </div>
                                    <Table id={`${id}_viewings`} columns={columns} rows={data} />
                                </>
                            ) : (
                                <EmptyState id={'emptyState'} icon={faCalendarDays}
                                            caption={'No viewings found'}
                                            text={'Click the button below to begin scheduling a new property viewing.'}
                                            action={initNewViewing}
                                            actionLabel={'Schedule Viewing'}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <LoadingState id={`${id}_loading`}/>
            )}
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    )
}