import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {GridActionsCellItem, GridRowId, GridRowParams} from '@mui/x-data-grid'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPen,
    faTrash,
    faListCheck,
} from '@fortawesome/free-solid-svg-icons'

//-- TABLE AND TABLE DEFINITIONS
import {Table} from 'components/table/Table'
import {featuresColumnDef} from 'metadata/TableDefinitions'

//-- PAGE COMPONENTS
import {CommonFilter} from 'components/commonFilter/CommonFilter'
import {PrimaryButton} from 'components/button/Button'
import {LoadingState} from 'components/loadingState/LoadingState'
import {Growl} from 'components/growl/Growl'
import {EmptyState} from 'components/emptyState/EmptyState'

//-- SUB PAGES
import {Feature} from './Feature'

//-- UTILITIES AND TYPES
import {applyView, getViewId, growl, randomNumber} from 'functions/CommonUtils'
import {GrowlType, IFeature} from 'types'

//-- API AND ACTIONS
import {
    apiDeleteFeature,
    apiListFeatures,
} from 'api'

//-- STYLES
import './Features.scss'


/***
 * LOCAL CONSTANTS
 */
const EDIT_VIEW_ID= 'feature'
const NEW_VIEW_ID= 'newFeature'


/***
 * INTERFACES
 */
interface IFeaturesProps {
    id?: string,
    setPageSubTitle: Function
}


/**
 * Functional Component - Features
 * @param id
 * @param setPageSubTitle
 * @constructor
 */
export const Features: React.FC<IFeaturesProps> = ({
    id = 'featuresPage',
    setPageSubTitle,
}: IFeaturesProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [featureId, setFeatureId] = useState<number|string|undefined>(getViewId(EDIT_VIEW_ID))
    const [data, setData] = useState<IFeature[]>([])
    const [loaded, setLoaded] = useState(false)
    const [feature, setFeature] = useState<IFeature|undefined>()
    const [associationFilter, setAssociationFilter] = useState<boolean>()
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        setLoaded(false)
        if (accessToken) {
            if (associationFilter !== undefined) {
                apiListFeatures(accessToken, setData, setLoaded, associationFilter, true)
            } else {
                apiListFeatures(accessToken, setData, setLoaded, undefined, true)
            }
        }
    }, [associationFilter, accessToken])

    useEffect(() => {
        if (getViewId(NEW_VIEW_ID)) {
            initNewFeature()
        }
    }, [getViewId(NEW_VIEW_ID)])

    useEffect(() => {
        if (data && data.length && featureId) {
            const value: IFeature | undefined = data.find((feature) => feature.id === featureId)
            setFeature(value)
            setPageSubTitle(`Feature Editor`)
        }
    }, [featureId, data])



    /***
     * UTILITY FUNCTIONS
     */
    const initNewFeature = () => {
        const initFeature: IFeature = {
            name: ''
        }
        setFeature(initFeature)
        applyView(NEW_VIEW_ID, 'true')
        setPageSubTitle(`New Feature`)
    }

    const handleResetEditor = () => {
        setFeature(undefined)
        setFeatureId(undefined)
        setPageSubTitle(undefined)
        applyView(EDIT_VIEW_ID, undefined)
        applyView(NEW_VIEW_ID, undefined)
    }

    const handleSave = () => {
        growl(`${feature ? feature.name : 'Feature'} Saved Successfully`, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        handleResetEditor()
    }


    /***
     * CALLBACK FUNCTIONS
     */
    const editFeature = React.useCallback(
        (feature: IFeature) => () => {
            if (feature?.id) {
                setFeatureId(feature.id)
                setFeature(feature)
                applyView(EDIT_VIEW_ID, feature?.id)
                applyView(NEW_VIEW_ID, undefined)
            } else {
                setTimeout(() => {
                    growl('Unable to edit selected feature', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
                }, 100)
            }
        },
        [data],
    )

    const deleteFeature = React.useCallback(
        (id: GridRowId) => () => {
            apiDeleteFeature(accessToken, id, setData, data, 'Feature Successfully Deleted', setGrowlMessage, setGrowlType, setGrowlInstance)
        },
        [data],
    )


    /***
     * ICONS
     */
    const editActionIcon = (featureName: string) => <FontAwesomeIcon icon={faPen} title={`Edit ${featureName}`} className='datagrid-action-icon' />
    const deleteActionIcon = (featureName: string) => <FontAwesomeIcon icon={faTrash} title={`Delete ${featureName}`} className='datagrid-action-icon' />


    /***
     * DATAGRID COLUMNS
     */
    const columns= [...featuresColumnDef,
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                    icon={editActionIcon(params.row.name)}
                    label='Edit'
                    onClick={editFeature(params.row)}
                />,
                <GridActionsCellItem
                    icon={deleteActionIcon(params.row.name)}
                    label='Delete'
                    disabled={params.row.associatedProperties > 0}
                    onClick={deleteFeature(params.id)}
                />,
            ]
        }
    ]

    return(
        <>
            {loaded  ? (
                <>
                    {feature ? (
                        <>
                            <Feature    id='featurePage' feature={feature} setData={setData} data={data}
                                        handleBack={handleResetEditor} handleSave={handleSave} />
                        </>
                    ) : featureId === undefined && (
                        <>
                            {data?.length || associationFilter !== undefined ? (
                                <>
                                    <div id={`${id}_header`} className='base_header'>
                                        <CommonFilter   id={`${id}_featuresFilter`} title='Filter Features'
                                                        associationFilter={associationFilter} setAssociationFilter={setAssociationFilter}
                                        />
                                        <div className='controls'>
                                            <PrimaryButton  id={`${id}_new`} onClick={() => initNewFeature()}
                                                            startIcon={<FontAwesomeIcon icon={faListCheck} />}
                                                            label='New Feature' />
                                        </div>
                                    </div>
                                    <Table id={`${id}_features`} columns={columns} rows={data} />
                                </>
                            ) : (
                                <EmptyState id={'emptyState'} icon={faListCheck}
                                            caption={'No features found'}
                                            text={'Click the button below to add a new property feature.'}
                                            action={initNewFeature}
                                            actionLabel={'Add New Feature'}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <LoadingState id={`${id}_loading`}/>
            )}
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    )
}