import React from 'react'
import './Logo.scss'


interface ILogoProps {
    id: string,
}

export const Logo: React.FC<ILogoProps> = ({
    id,
}: ILogoProps) => {

    return (
        <header id={id} className='logo-panel'>
            <div className='logo'>
                <span className='logo-text1'>{`//`}</span>
                <span className='logo-text2'>GEM</span>
                <span className='logo-text3'>Properties</span>
            </div>
        </header>
    )
}