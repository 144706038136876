import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getMenuItemIcon, MenuItemType} from 'enums/Types'
import {handleNavContacts} from 'functions/NavFunctions'
import {IContact} from 'types'
import {highlightIfMatched, NO_EMAIL, NO_PHONE} from './commonFormatter'

export const formatContactResults = (
    contact: IContact,
    searchText: string|undefined,
    type: string,
    applyView: Function,
    setSelectedMenuItem: Function
): JSX.Element | undefined => {
    return (
        <div    id={`${type}_${contact.id}`}
                key={`${contact.name}_${type}_${contact.id}`}
                className={'search-result-link'}>
            <div    className={'search-result-title'}
                    onClick={() => {
                        applyView('contact', contact.id)
                        setSelectedMenuItem(MenuItemType.CONTACTS)
                        handleNavContacts()
                    }}>
                <FontAwesomeIcon icon={getMenuItemIcon(MenuItemType.CONTACTS)} fixedWidth size={'sm'} />{' '}
                {highlightIfMatched(searchText, contact.name)}
            </div>
            <div className={'search-result-description'}>
                This contact is associated with{' '}
                {contact.associatedProperties === 1
                    ? `${contact.associatedProperties} property`
                    : `${contact.associatedProperties ? contact.associatedProperties : 0} properties`}.
                <div className={'supplemental'}>
                    (Contact Info:{' '}
                    <a className={'link'} href={`mailto:${contact.email1 || contact.email2}`}>
                        {highlightIfMatched(searchText, contact.email1 || contact.email2 || NO_EMAIL)}
                    </a> |{' '}
                    {highlightIfMatched(searchText, contact.phone1 || contact.phone2 || NO_PHONE)}),{' '}
                </div>
            </div>
        </div>
    )
}