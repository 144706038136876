import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getMenuItemIcon, MenuItemType} from 'enums/Types'
import {formatDate, reversedEnum} from 'functions/CommonUtils'
import {handleNavContacts, handleNavViewings} from 'functions/NavFunctions'
import {Island, IViewing, ViewingStatus} from 'types'
import {highlightIfMatched, NO_EMAIL, NO_PHONE} from './commonFormatter'

export const formatViewingResults = (
    viewing: IViewing,
    searchText: string|undefined,
    type: string,
    applyView: Function,
    setSelectedMenuItem: Function
): JSX.Element | undefined => {
    return (
        <div    id={`${type}_${viewing.id}`}
                key={`${viewing.property!.propertyName}_${type}_${viewing.id}`}
                className={'search-result-link'}>
            <div    className={'search-result-title'}
                    onClick={() => {
                        applyView('viewing', viewing.id)
                        setSelectedMenuItem(MenuItemType.VIEWINGS)
                        handleNavViewings()
                    }}>
                <FontAwesomeIcon icon={getMenuItemIcon(MenuItemType.VIEWINGS)} fixedWidth size={'sm'} />{' '}
                {highlightIfMatched(searchText, viewing.property!.propertyName)} Viewing, located at{' '}
                {highlightIfMatched(searchText, viewing.property!.location.name)},{' '}
                {highlightIfMatched(searchText, reversedEnum(Island)[viewing.property!.location.island as Island])}
            </div>
            <div className={'search-result-description'}>
                        <span   className={'link'}
                                onClick={() => {
                                    applyView('contact', viewing.client.id)
                                    setSelectedMenuItem(MenuItemType.CONTACTS)
                                    handleNavContacts()
                                }}>
                            {highlightIfMatched(searchText, viewing.client.name)}
                        </span> requested a property viewing for{' '}
                {formatDate(viewing.schedule, 'medium')} which{' '}
                {viewing.cancelled || reversedEnum(ViewingStatus)[viewing.status as ViewingStatus] === ViewingStatus.COMPLETED.valueOf() ? `has been ` : `is presently `}
                {viewing.cancelled
                    ? (highlightIfMatched(searchText, ViewingStatus.CANCELLED))
                    : (highlightIfMatched(searchText, reversedEnum(ViewingStatus)[viewing.status as ViewingStatus]))}
                <div className={'supplemental'}>
                    <div>
                        <strong>Property Info: </strong>
                        {highlightIfMatched(searchText, viewing.property!.caption)}... {highlightIfMatched(searchText, viewing.property!.description)}
                    </div>
                    <div>
                        <strong>Client Info: </strong> {highlightIfMatched(searchText, viewing.client.name)} |{' '}
                        <a className={'link'} href={`mailto:${viewing.client.email1 || viewing.client.email2}`}>
                            {highlightIfMatched(searchText, viewing.client.email1 || viewing.client.email2 || NO_EMAIL)}
                        </a> |{' '}
                        {highlightIfMatched(searchText, viewing.client.phone1 || viewing.client.phone2 || NO_PHONE)}
                    </div>
                    <div>
                        <strong>Owner Info:</strong> {highlightIfMatched(searchText, viewing.property!.propertyOwner.name)} |{' '}
                        <a className={'link'} href={`mailto:${viewing.property!.propertyOwner.email1 || viewing.property!.propertyOwner.email2}`}>
                            {highlightIfMatched(searchText, viewing.property!.propertyOwner.email1 || viewing.property!.propertyOwner.email2 || NO_EMAIL)}
                        </a> |{' '}
                        {highlightIfMatched(searchText, viewing.property!.propertyOwner.phone1 || viewing.property!.propertyOwner.phone2 || NO_PHONE)}
                    </div>
                </div>
            </div>
        </div>
    )
}