import React, {useEffect, useState} from 'react'
import {Alert, Snackbar } from '@mui/material'
import {GrowlType} from 'types'

interface IGrowlProps {
    id: string,
    message: string,
    type?: GrowlType,
    instance: number,
}

export const Growl: React.FC<IGrowlProps> = ({
    id,
    message,
    type = 'success',
    instance,
}: IGrowlProps) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>()
    const [snackbarMessage, setSnackbarMessage] = useState<string|undefined>()

    useEffect(() => {
        if (message && instance) {
            showGrowl(message)

            switch(type) {
                case 'info': {
                    console.error(message)
                    break
                }
                case 'warning': {
                    console.error(message)
                    break
                }
                case 'error': {
                    console.error(message)
                    break
                }
            }
        }
    }, [instance])

    const showGrowl = (message:string) => {
        if (showSnackbar) {
            setShowSnackbar(false)
        }
        setSnackbarMessage(message)
        setShowSnackbar(true)
    }

    const handleCloseMessage = () => {
        setShowSnackbar(false)
    }

    return (
        <Snackbar
            id={id}
            open={showSnackbar}
            onClose={handleCloseMessage}
            autoHideDuration={6000}
            style={{ top: 88 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleCloseMessage} severity={type} variant='filled' >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    )
}