import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import './LocationPin.scss'

interface ILocationPinProps {
    label?: string,
    lat: number,
    lng: number,
}
export const LocationPin: React.FC<ILocationPinProps> = ({
    label,
}) => (
    <div className="locationMapPin">
        <FontAwesomeIcon icon={faLocationDot} className='icon' />
        {label && (<p className='label'>{label}</p>)}
    </div>
)

export default LocationPin