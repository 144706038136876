import React, {useState} from 'react'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {IconButton, PrimaryButton} from 'components/button/Button'
import Stack from '@mui/material/Stack'
import './SearchBox.scss'

interface ISearchBoxProps {
    id?: string,
    searchText: string|undefined,
    handleSubmit: Function,
    handleClear?: Function,
}

export const SearchBox: React.FC<ISearchBoxProps> = ({
    id = 'searchBox',
    searchText,
    handleSubmit,
    handleClear = () => {},
}: ISearchBoxProps) => {
    const [localSearchText, setLocalSearchText] = useState<string>(searchText ? searchText : '')

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setLocalSearchText(e.currentTarget.value)
    }

    const handleSearchInputKeyDown = (e: React.KeyboardEvent): void => {
        if (e?.key === 'Enter') {
            applySearch()
        }
    }

    const preventDefaultSubmit = (e: any) => {
        e.preventDefault()
    }

    const applySearch = (): void => {
        handleSubmit(localSearchText)
    }

    const resetSearch = (): void => {
        setLocalSearchText('')
        handleClear()
    }

    return (
        <form id={`${id}_searchForm`} className='search-form' onSubmit={preventDefaultSubmit}>
            <Stack spacing={2} direction='row'>
                <div className='search-input-wrapper flex items-center'>
                    <input id={`${id}_searchInput`}
                           type='text'
                           className='search-input border w-full rounded-sm'
                           placeholder='Search for Properties, Locations, Features, Contacts and Viewings'
                           value={localSearchText}
                           maxLength={85}
                           onChange={handleSearchInput}
                           onKeyDown={handleSearchInputKeyDown}
                           autoComplete={'off'}
                    />
                    { localSearchText && (
                        <>
                            <IconButton id={`${id}_clearSearchButton`} label={faXmark} className='clear-search-button' onClick={resetSearch} />
                        </>
                    )}
                </div>
                { localSearchText && (
                    <PrimaryButton
                        id={`${id}_applySearchButton`}
                        label='Apply' onClick={applySearch}
                    />
                )}
            </Stack>
        </form>
    )
}