import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react'
import {AUTH0_AUDIENCE} from 'types'

const domain: string = process.env.REACT_APP_AUTH0_DOMAIN!
const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID!

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider domain={domain} clientId={clientId} authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
    }}>
        <App/>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);