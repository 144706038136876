import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import {Growl} from 'components/growl/Growl'
import { renderSelectedContact } from 'components/titledContact/TitledContact'

//-- UTILITIES AND TYPES
import {growl, isDateBefore, randomNumber, roundToNearestHour} from 'functions/CommonUtils'
import {GrowlType, IContact, IProperty, IViewing, SELECT_ONE} from 'types'

//-- API AND ACTIONS
import {apiListContacts, apiListProperties, apiUpsertViewing} from 'api'

//-- STYLES
import './Viewing.scss'


/***
 * INTERFACES
 */
interface IViewingProps {
    id?: string,
    viewing: IViewing,
    setData: Function,
    data: IViewing[],
    handleBack: Function,
    handleSave: Function,
}
export const Viewing: React.FC<IViewingProps> = ({
    id = 'viewingPage',
    viewing,
    setData,
    data,
    handleBack,
    handleSave,
}: IViewingProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [containerHeight, setContainerHeight] = useState(0)
    const [schedule, setSchedule] = useState(dayjs(moment(Object.keys(viewing.schedule).length ? viewing.schedule : roundToNearestHour()).toISOString()))
    const [properties, setProperties] = useState<IProperty[]>([])
    const [property, setProperty] = useState(viewing.property)
    const [contacts, setContacts] = useState<IContact[]>([])
    const [client, setClient] = useState<IContact>(viewing.client)
    const [clientError, setClientError] = useState(false)
    const [propertyError, setPropertyError] = useState(false)
    const [scheduleError, setScheduleError] = useState(false)
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())

    //-- Local References
    const  ref = useRef<HTMLDivElement>(null)


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        if (accessToken) {
            apiListContacts(accessToken, setContacts, () => {})
            apiListProperties(accessToken, setProperties, () => {})
            updateContainerHeight()
            window.addEventListener('resize', updateContainerHeight)
            return () => window.removeEventListener('resize', updateContainerHeight)
        }
    }, [accessToken])


    /***
     * UTILITY FUNCTIONS
     */
    const updateContainerHeight = () => {
        setContainerHeight(ref?.current?.clientHeight!)
    }

    const _handleSave = () => {
        const _clientError = client === undefined || client === null || client.id === -1 || Object.keys(client).length === 0
        const _propertyError = property === undefined || property === null || property.id === -1 || Object.keys(property).length === 0
        const _scheduleError = schedule === undefined || schedule === null || isDateBefore(schedule.toDate(), new Date())

        setClientError(_clientError)
        setPropertyError(_propertyError)
        setScheduleError(_scheduleError)

        if (viewing) {
            if (!_clientError && !_propertyError && !_scheduleError) {
                viewing.client = client
                viewing.property = property
                viewing.schedule = schedule.toISOString()

                const payload: any = {
                    ...viewing
                }
                apiUpsertViewing(accessToken, setData, data, payload)
                handleSave()
            } else {
                growl('Missing Required Field(s)', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        }
    }

    const handleClientChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value
        if (typeof value === 'string') {
            setClient(JSON.parse(value))
        } else if (Array.isArray(value) && value.length > 0) {
            setClient(JSON.parse(value[0]))
        }
        setClientError(false)
    }

    const handlePropertyChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value
        if (typeof value === 'string') {
            setProperty(JSON.parse(value))
        } else if (Array.isArray(value) && value.length > 0) {
            setProperty(JSON.parse(value[0]))
        }
        setPropertyError(false)
    }

    const renderSelectedProperty = (value: string[]) => {
        const property: IProperty = value && value.length > 0 && JSON.parse(value[0])
        return (
            <div title={`${property.propertyName}${(property.propertyOwner?.name) && ` - Owner: ${property.propertyOwner.name}`}`}>
                {property.propertyName}
            </div>
        )
    }

    const handleScheduleChange = (newValue: dayjs.Dayjs | null) => {
        if (newValue) {
            if (isDateBefore(newValue.toDate(), new Date())) {
                growl('Select a Future Viewing Date')
                setScheduleError(true)
            } else {
                setScheduleError(false)
            }
            setSchedule(newValue)
        }
    }


    /***
     * RENDER PROPS
     */
    const itemXs:number = 1
    const itemSm:number = 1
    const itemMd:number = 2
    const itemLg:number = 2
    const MENU_ITEM_HEIGHT = 48;
    const MENU_ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
            },
        },
    }

    return (
        <>
            <div id={id} className='page_container' ref={ref}>
                <Box component='form' noValidate autoComplete='off' className='viewing_editor' style={{height: containerHeight}}>
                    <Grid container spacing={{ xs: 3, sm: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <FormControl variant='filled' error={clientError} className='textInput' required>
                                <InputLabel id='viewingClientLabel'>Client</InputLabel>
                                <Select
                                    id='viewingClient'
                                    labelId='viewingClientLabel'
                                    value={[JSON.stringify(client)]}
                                    onChange={handleClientChange}
                                    renderValue={renderSelectedContact}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key={-1} value={-1}>{SELECT_ONE}</MenuItem>
                                    {
                                        contacts.map((contact) =>
                                            <MenuItem key={contact.id} value={JSON.stringify(contact)}>
                                                <div className='selectOne_contact_menu_item'>
                                                    <div className='label'>{contact.name}</div>
                                                    {(contact.phone1 || contact.phone2) && (
                                                        <span className='subLabel'>
                                                            <strong>Phone: </strong>
                                                            {`${contact.phone1 || contact.phone2}`}
                                                        </span>
                                                    )}
                                                    {(contact.email1 || contact.email2) && (
                                                        <span className='subLabel'>
                                                            <strong>Email: </strong>
                                                            {`${contact.email1 || contact.email2}`}
                                                        </span>
                                                    )}
                                                </div>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <FormControl variant='filled' error={propertyError} className='textInput' required>
                                <InputLabel id='propertyLabel'>Property</InputLabel>
                                <Select
                                    id='property'
                                    labelId='propertyLabel'
                                    value={[JSON.stringify(property)]}
                                    onChange={handlePropertyChange}
                                    renderValue={renderSelectedProperty}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key={-1} value={-1}>{SELECT_ONE}</MenuItem>
                                    {
                                        properties.map((property) =>
                                            <MenuItem key={property.id} value={JSON.stringify(property)}>
                                                <div className='selectOne_contact_menu_item'>
                                                    <div className='label'>{property.propertyName}</div>
                                                    {(property.propertyOwner?.name) && (
                                                        <span className='subLabel'>
                                                            <strong>Owner: </strong>
                                                            {`${property.propertyOwner.name}`}
                                                        </span>
                                                    )}
                                                </div>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormControl error={scheduleError} className='datePicker' required>
                                    <DateTimePicker label='Viewing Date'
                                                    minDate={dayjs(roundToNearestHour())}
                                                    disablePast
                                                    value={schedule}
                                                    onChange={handleScheduleChange}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>
                <div className='viewing_editor_buttons'>
                    <SecondaryButton id={`${id}_back_button`} label='Back' startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />} onClick={handleBack}  />
                    <PrimaryButton id={`${id}_save_button`} label='Save' startIcon={<FontAwesomeIcon icon={faSave} />} onClick={_handleSave} />
                </div>
                <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
            </div>
        </>
    )
}