import { GridColDef, GridValueGetterParams, GridComparatorFn } from '@mui/x-data-grid'
import {PropertyType, PropertyStatus, PriceFreq, IContact, ILocation, ViewingStatus, Island} from 'types'
import {formatCurrency, formatDate, parseLocaleCurrency, reversedEnum} from 'functions/CommonUtils'



const priceComparator: GridComparatorFn<string> = (v1, v2) => {
    return parseLocaleCurrency(v1, 'en-US') - parseLocaleCurrency(v2, 'en-US')
}

const decodePriceFreq = (priceFreq: string) => {
    return PriceFreq[priceFreq as keyof typeof PriceFreq] ?? ''
}

export const decodeContact = (contact: IContact) => {
    return `${contact && contact.name}`
}

export const decodeLocation = (location: ILocation) => {
    return `${location.name}, ${reversedEnum(Island)[location.island as Island]}`
}

export const decodePropertyType = (type: string) => {
    return PropertyType[type as keyof typeof PropertyType]
}

export const decodeIsland = (island: string) => {
    return Island[island as keyof typeof Island]
}

export const decodePropertyStatus = (status: string) => {
    return PropertyStatus[status as keyof typeof PropertyStatus]
}

export const decodeViewingStatus = (status: string) => {
    return ViewingStatus[status as keyof typeof ViewingStatus]
}

export const decodeDate = (dateStr: string, variant?: 'long' | 'medium' | 'short') => {
    return formatDate(dateStr, variant)
}

export const propertiesColumnDef: GridColDef[] = [
    {
        field: 'id',
        type: 'number',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'propertyName',
        headerName: 'Property Name',
        flex: 1.5,
    },
    {
        field: 'propertyOwner',
        headerName: 'Property Owner',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeContact(params.row.propertyOwner)}`
    },
    {
        field: 'bedrooms',
        headerName: 'Bed',
        type: 'number',
        width: 100,
    },
    {
        field: 'bathrooms',
        headerName: 'Bath',
        type: 'number',
        width: 100,
    },
    {
        field: 'location',
        headerName: 'Location',
        flex: 2,
        valueGetter: (params: GridValueGetterParams) => `${decodeLocation(params.row.location)}`,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => `${decodePropertyType(params.row.propertyType)}`,
    },
    {
        field: 'price',
        headerName: 'Price',
        type: 'number',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => params.row.price ? `${formatCurrency(params.row.price)} ${decodePriceFreq(params.row.priceFreq)}` : '',
        sortComparator: priceComparator,
    },
];

export const locationsColumnDef: GridColDef[] = [
    {
        field: 'id',
        type: 'number',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'name',
        headerName: 'Location Name',
        flex: 1.5,
    },
    {
        field: 'island',
        headerName: 'Island',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeIsland(params.row.island)}`
    },
    {
        field: 'associatedProperties',
        headerName: 'Associated Properties',
        flex: 1.5,
    },
];

export const featuresColumnDef: GridColDef[] = [
    {
        field: 'id',
        type: 'number',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'name',
        headerName: 'Feature Name',
        flex: 1.5,
    },
    {
        field: 'associatedProperties',
        headerName: 'Associated Properties',
        flex: 1.5,
    },
];

export const contactsColumnDef: GridColDef[] = [
    {
        field: 'id',
        type: 'number',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'name',
        headerName: 'Feature Name',
        flex: 1.5,
    },
    {
        field: 'phone1',
        headerName: 'Primary Phone Number',
        flex: 1.5,
    },
    {
        field: 'phone2',
        headerName: 'Secondary Phone Number',
        flex: 1.5,
    },
    {
        field: 'email1',
        headerName: 'Primary Email Address',
        flex: 1.5,
    },
    {
        field: 'email2',
        headerName: 'Secondary Email Address',
        flex: 1.5,
    },
    {
        field: 'associatedProperties',
        headerName: 'Associated Properties',
        flex: 1.5,
    },
];

export const viewingsColumnDef: GridColDef[] = [
    {
        field: 'schedule',
        headerName: 'Viewing Schedule',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeDate(params.row.schedule, 'long')}`
    },
    {
        field: 'propertyName',
        headerName: 'Property Name',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${params.row.property.propertyName}`
    },
    {
        field: 'owner',
        headerName: 'Owner',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeContact(params.row.property.propertyOwner)}`
    },
    {
        field: 'client',
        headerName: 'Client',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeContact(params.row.client)}`
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1.5,
        valueGetter: (params: GridValueGetterParams) => `${decodeViewingStatus(params.row.status)}`
    },
];
