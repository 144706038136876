import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getMenuItemIcon, MenuItemType} from 'enums/Types'
import {formatCurrency, reversedEnum} from 'functions/CommonUtils'
import {handleNavProperties} from 'functions/NavFunctions'
import {FurnishedType, IFeature, IProperty, Island, PriceFreq, PropertyStatus, PropertyType} from 'types'
import {highlightIfMatched, NO_EMAIL, NO_PHONE} from './commonFormatter'

export const formatPropertyResults = (
    property: IProperty,
    searchText: string|undefined,
    type: string,
    applyView: Function,
    setSelectedMenuItem: Function
): JSX.Element | undefined => {
    return (
        <div    id={`${type}_${property.id}`}
                    key={`${property.propertyName}_${type}_${property.id}`}
                    className={'search-result-link'}>
            <div    className={'search-result-title'}
                    onClick={() => {
                        applyView('property', property.id)
                        setSelectedMenuItem(MenuItemType.PROPERTIES)
                        handleNavProperties()
                    }}>
                <FontAwesomeIcon icon={getMenuItemIcon(MenuItemType.PROPERTIES)} fixedWidth size={'sm'} />{' '}
                {highlightIfMatched(searchText, property.propertyName)}
            </div>
            <div className={'search-result-description'}>
                {highlightIfMatched(searchText, property.caption)}{property.description ? `... ` : ''}{highlightIfMatched(searchText, property.description)}
                <div className={'supplemental'}>
                    Owned by {highlightIfMatched(searchText, property.propertyOwner.name)}{' '}
                    (<strong>Contact Info:</strong>{' '}
                    <a className={'link'} href={`mailto:${property.propertyOwner.email1 || property.propertyOwner.email2}`}>
                        {highlightIfMatched(searchText, property.propertyOwner.email1 || property.propertyOwner.email2 || NO_EMAIL)}
                    </a> |{' '}
                    {highlightIfMatched(searchText, property.propertyOwner.phone1 || property.propertyOwner.phone2 || NO_PHONE)}),{' '}
                    this {property.featured ? highlightIfMatched(searchText, 'Featured') : ''}{property.featured ? ' ' : ''}
                    {highlightIfMatched(searchText, reversedEnum(PropertyType)[property.propertyType as PropertyType])}{' '}
                    is listed as {highlightIfMatched(searchText, reversedEnum(FurnishedType)[property.furnishedType as FurnishedType])}{' '}
                    at a price of {formatCurrency(property.price)}{property.priceFreq ? ` ${reversedEnum(PriceFreq)[property.priceFreq as PriceFreq]}` : ''}{' '}
                    and Located at {highlightIfMatched(searchText, property.location.name)} {highlightIfMatched(searchText, reversedEnum(Island)[property.location.island as Island])}.
                    <div>
                        <strong>Status: </strong>{highlightIfMatched(searchText, reversedEnum(PropertyStatus)[property.status as PropertyStatus])}
                    </div>
                    {property.bedrooms || property.bathrooms || property.floors ? (
                        <div>
                            <strong>Floor Plan:</strong> {property.bedrooms ? `${property.bedrooms} bedrooms ` : ''}{property.bathrooms ? `${property.bathrooms} bathrooms ` : ''}
                            {property.floors ? `${property.floors} floor${property.floors !== 1 ? 's' : ''} `: ''}
                        </div>
                    ) : (<></>)}
                    <>
                        {property.features?.length ? (<strong>Features: </strong>) : ''}{property.features?.length ? property.features.map((feature: IFeature, idx: number): JSX.Element[] => {
                        let output: JSX.Element[] = []
                        output.push(<span key={`feature_${feature.name}_${feature.id}_${idx}`}>{idx !== 0 ? ', ' : ''}{highlightIfMatched(searchText, feature.name)}</span>)
                        return output
                    }) : ''}
                    </>
                </div>
            </div>
        </div>
    )
}