import React from 'react'

/***
 * SHARED CONSTANTS
 */
export const NO_EMAIL: string = 'No Email'
export const NO_PHONE: string = 'No Phone Number'

/***
 * COMMON UTILITY FUNCTIONS
 */
export const highlightIfMatched = (criteria: string|undefined, value: string|undefined, key?: string):JSX.Element | string => {
    if (criteria && value && !value.includes(NO_EMAIL) && !value.includes(NO_PHONE) && value.toLowerCase().includes(criteria.toLowerCase())) {
        return (
            <div className={'highlight'} key={key}>{value.toString()}</div>
        )
    }
    return value || ''
}
