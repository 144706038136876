import { MenuItemType } from 'enums/Types'

export const handleNavDefault = () => {
    handleNavProperties()
}

// export const handleNavDashboard = () => {
//     sessionStorage.setItem('page', MenuItemType.DASHBOARD)
// }

export const handleNavProperties = () => {
    sessionStorage.setItem('page', MenuItemType.PROPERTIES)
}

export const handleNavLocations = () => {
    sessionStorage.setItem('page', MenuItemType.LOCATIONS)
}

export const handleNavFeatures = () => {
    sessionStorage.setItem('page', MenuItemType.FEATURES)
}

export const handleNavContacts = () => {
    sessionStorage.setItem('page', MenuItemType.CONTACTS)
}

export const handleNavViewings = () => {
    sessionStorage.setItem('page', MenuItemType.VIEWINGS)
}

export const handleNavProfile = () => {
    sessionStorage.setItem('page', MenuItemType.PROFILE)
}

export const handleNavSettings = () => {
    sessionStorage.setItem('page', MenuItemType.SETTINGS)
}

export const handleNavSearch = () => {
    sessionStorage.setItem('page', MenuItemType.SEARCH)
}

export const handleLogout = () => {
    console.log('Logging out')
    sessionStorage.clear()
    window.location.reload()
}

export const isValidPage = (page: string): boolean => {
    return page ? Object.keys(MenuItemType).includes(page.toUpperCase()) : false
}

export const isLoginPageActive = () => {
    return sessionStorage.getItem('page') === null
}

export const isPageActive = (page: MenuItemType) => {
    return sessionStorage.getItem('page') === page
}

export const getActivePage = (): string|null => {
    const page = sessionStorage.getItem('page')
    return page
}

export const handleMenuNav = (currentMenuItem: MenuItemType, selectedMenuItem: MenuItemType, setSelectedMenuItem: Function) => {
    if (currentMenuItem !== selectedMenuItem) {
        setSelectedMenuItem(currentMenuItem)
        switch (currentMenuItem) {
            // case MenuItemType.DASHBOARD: {
            //     handleNavDashboard()
            //     break
            // }
            case MenuItemType.PROPERTIES: {
                handleNavProperties()
                break
            }
            case MenuItemType.LOCATIONS: {
                handleNavLocations()
                break
            }
            case MenuItemType.FEATURES: {
                handleNavFeatures()
                break
            }
            case MenuItemType.CONTACTS: {
                handleNavContacts()
                break
            }
            case MenuItemType.VIEWINGS: {
                handleNavViewings()
                break
            }
            default: {
                console.error('Unknown menu item')
                break
            }
        }
    }
}
