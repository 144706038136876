import React from 'react'
import {IViewing, ViewingStatus} from 'types'
import {apiListViewings, apiUpsertViewing} from 'api'

export const applyViewingCancellation = (   accessToken: string|undefined,
                                            setData: Function,
                                            data: IViewing[],
                                            payload: any,
                                            statusFilter: ViewingStatus|undefined,
                                            setLoaded: Function,
                                            growlMessage?: string,
                                            setGrowlMessage?: Function,
                                            setGrowlType?: Function,
                                            setGrowlInstance?: Function) => {
    setTimeout(() => {
        apiUpsertViewing(accessToken, setData, data, payload, growlMessage, setGrowlMessage, setGrowlType, setGrowlInstance)
            .then(() => {
                    setTimeout(() => {
                        apiListViewings(accessToken, setData, setLoaded, statusFilter)
                    }, 1)
                }
            )
    })
}
