import React, {useEffect, useRef, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleXmark,
    faLocationDot,
    faMapLocationDot,
    faSave
} from '@fortawesome/free-solid-svg-icons'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import Map from 'components/map/Map'
import {Growl} from 'components/growl/Growl'
import { renderSelectedContact } from 'components/titledContact/TitledContact'

//-- UTILITIES AND TYPES
import {growl, randomNumber, reversedEnum, toNumber} from 'functions/CommonUtils'
import {
    FurnishedType,
    IFeature,
    ILocation,
    IContact,
    IProperty,
    isCommercialProperty,
    isLandSale,
    isRentalProperty,
    isResidentialProperty,
    isSaleProperty,
    PriceFreq,
    ICoordinates,
    GrowlType,
    Island,
    PropertyType,
    SELECT_ONE,
    DEFAULT_CONTACT,
    DEFAULT_LOCATION,
} from 'types'

//-- API AND ACTIONS
import {apiUpsertProperty, apiListFeatures, apiListLocations, apiListContacts} from 'api'

//-- STYLES
import './Property.scss'


/***
 * INTERFACES
 */
interface IPropertyProps {
    id?: string,
    property: IProperty,
    setData: Function,
    data: IProperty[],
    handleBack: Function,
    handleSave: Function,
}

/**
 * Functional Component - Property
 * @param id
 * @param property
 * @param setData
 * @param data
 * @param handleBack
 * @param handleSave
 * @constructor
 */
export const Property: React.FC<IPropertyProps> = ({
    id = 'propertyPage',
    property,
    setData,
    data,
    handleBack,
    handleSave,
}: IPropertyProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [containerHeight, setContainerHeight] = useState(0)
    const [showMap, setShowMap] = useState(false)
    const [locations, setLocations] = useState<ILocation[]>([])
    const [contacts, setContacts] = useState<IContact[]>([])
    const [propertyType] = useState(property.propertyType)
    const [propertyName, setPropertyName] = useState(property.propertyName)
    const [propertyOwner, setPropertyOwner] = useState(property.propertyOwner)
    const [bedrooms, setBedrooms] = useState(property.bedrooms)
    const [bathrooms, setBathrooms] = useState(property.bathrooms)
    const [floors, setFloors] = useState(property.floors)
    const [acreage, setAcreage] = useState(property.acreage)
    const [location, setLocation] = useState(property.location)
    const [coordinates, setCoordinates] = useState(property.coordinates)
    const [latitude, setLatitude] = useState(property.coordinates.lat)
    const [longitude, setLongitude] = useState(property.coordinates.lng)
    const [features, setFeatures] = useState<IFeature[]>(property.features ? property.features : [])
    const [featureSelection, setFeatureSelection] = useState<IFeature[]>([])
    const [selectedFeatures, setSelectedFeatures] = useState<string[]>([])
    const [furnishedType, setFurnishedType] = useState<string|undefined>(property.furnishedType)
    const [price, setPrice] = useState(property.price)
    const [priceFreq, setPriceFreq] = useState(property.priceFreq)
    const [caption, setCaption] = useState(property.caption)
    const [description, setDescription] = useState(property.description)
    const [propertyNameError, setPropertyNameError] = useState(false)
    const [propertyOwnerError, setPropertyOwnerError] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [bedroomsError, setBedroomsError] = useState(false)
    const [bathroomsError, setBathroomsError] = useState(false)
    const [floorsError, setFloorsError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [captionError, setCaptionError] = useState(false)
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())

    //-- Local References
    const  ref = useRef<HTMLDivElement>(null)

    //-- Local Variables
    const isResidential = isResidentialProperty(property.propertyType)
    const isCommercial = isCommercialProperty(property.propertyType)
    const isRental = isRentalProperty(property.propertyType)
    const isSale = isSaleProperty(property.propertyType)
    const isResidentialRental = isResidential && isRental
    const isCommercialRental = isCommercial && isRental
    const isCommercialSale = isCommercial && isSale
    const isLand = isLandSale(property.propertyType)


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        if (accessToken) {
            apiListLocations(accessToken, setLocations, () => {})
            apiListContacts(accessToken, setContacts, () => {})
            apiListFeatures(accessToken, setFeatureSelection, () => {})
            initFeatureSelection()
            updateContainerHeight()
            window.addEventListener('resize', updateContainerHeight)
            return () => window.removeEventListener('resize', updateContainerHeight)
        }
    }, [accessToken])

    useEffect(() => {
        if (latitude && longitude) {
            setCoordinates({lat: latitude, lng: longitude})
        }
    }, [latitude, longitude])


    /***
     * UTILITY FUNCTIONS
     */
    const updateContainerHeight = () => {
        setContainerHeight(ref?.current?.clientHeight!)
    }

    const _handleSave = () => {
        const _propertyNameError = propertyName === undefined || propertyName === null || propertyName.length === 0
        const _propertyOwnerError = propertyOwner === undefined || propertyOwner === null || propertyOwner.id === -1 || Object.keys(propertyOwner).length === 0
        const _locationError = location === undefined || location === null || location.id === -1 || Object.keys(location).length === 0
        const _bedroomsError = (isResidential) && (bedrooms === undefined || bedrooms === null || bedrooms <= 0)
        const _bathroomsError = (isResidential || isCommercialRental) && (bathrooms === undefined || bathrooms === null || bathrooms <= 0)
        const _floorsError = (isResidential || isCommercialRental) && (floors === undefined || floors === null || floors <= 0)
        const _priceError = (isResidentialRental || isResidential || isCommercial || isLand) && (price === undefined || price === null || price <= 0)
        const _captionErrorError = captionError === undefined || captionError === null || caption.length === 0

        setPropertyNameError(_propertyNameError)
        setPropertyOwnerError(_propertyOwnerError)
        setLocationError(_locationError)
        setBedroomsError(_bedroomsError)
        setBathroomsError(_bathroomsError)
        setFloorsError(_floorsError)
        setPriceError(_priceError)
        setCaptionError(_captionErrorError)

        if (property) {
            if (!_propertyNameError && !_propertyOwnerError && !_locationError && !_bedroomsError && !_bathroomsError && !_floorsError && !_priceError && !_captionErrorError) {
                property.propertyName = propertyName
                property.propertyOwner = propertyOwner
                property.bedrooms = bedrooms
                property.bathrooms = bathrooms
                property.floors = floors
                property.acreage = acreage
                property.location = location
                property.coordinates = coordinates
                property.features = features
                property.propertyType = propertyType
                property.furnishedType = furnishedType
                property.price = price
                property.priceFreq = priceFreq
                property.caption = caption
                property.description = description

                const payload: any = {
                    ...property
                }
                apiUpsertProperty(accessToken, setData, data, payload).then(() => {})
                handleSave()
            } else {
                console.debug({_propertyNameError}, {_propertyOwnerError}, {_locationError}, {_bedroomsError}, {_bathroomsError}, {_floorsError}, {_priceError}, {_captionErrorError})
                growl('Missing Required Field(s)', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        }
    }

    const isPriceWithinRange = (value: string) => {
        const priceVal: number = toNumber(value, price)
        let maxPrice: number = 10000000
        switch (PropertyType[propertyType as keyof typeof PropertyType]) {
            case PropertyType.RESIDENTIAL_RENTAL:
            case PropertyType.COMMERCIAL:
            case PropertyType.SHORT_TERM_RENTAL: {
                maxPrice = 10000
                break
            }
            case PropertyType.RESIDENTIAL_SALE: {
                maxPrice = 1000000
                break
            }
            case PropertyType.COMMERCIAL_SALE:
            case PropertyType.LAND_SALE: {
                maxPrice = 10000000
                break
            }
        }
        return priceVal <= maxPrice
    }

    const handlePropertyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPropertyName(event.target.value)
        setPropertyNameError(false)
    }

    const handleOwnerChange = (event: SelectChangeEvent<string[]>) => {
        const value: string | string[] = event.target.value
        let selection: IContact|undefined

        if (typeof value === 'string') {
            selection = JSON.parse(value)
        } else if (Array.isArray(value) && value.length > 0) {
            selection = JSON.parse(value[0])
        }

        //-- Set Property Owner only if it is in the acceptable list of owners retrieved from API
        const propertyOwner: IContact|undefined = selection?.id ? contacts.find(contact => contact.id === selection?.id) : undefined
        if (propertyOwner) {
            setPropertyOwner(propertyOwner)
        }

        setPropertyOwnerError(false)
    }

    const handleLocationChange = (locationId: string) => {
        const location:ILocation|undefined = locations.find(location => location.id === parseInt(locationId))
        if (location) {
            setLocation(location)
        }
    }

    const handleLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.toString().length > 0) {
            setLatitude(toNumber(event.target.value, latitude))
        }
    }

    const handleLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.toString().length > 0) {
            setLongitude(Number(event.target.value))
        }
    }

    const applySelectedCoordinates = (coordinates: ICoordinates) => {
        if (coordinates) {
            setLatitude(coordinates.lat)
            setLongitude(coordinates.lng)
        }
    }

    const toFeatureArray = (value: string | string[]): IFeature[] => {
        let items: IFeature[] = []
        if (typeof value === 'string') {
            let obj:IFeature = JSON.parse(value)
            delete obj.associatedProperties
            items.push(obj)
        } else if (Array.isArray(value)) {
            value.map(i => {
                let obj:IFeature = JSON.parse(i)
                delete obj.associatedProperties
                items.push(obj)
            })
        }
        return items
    }

    const initFeatureSelection = () => {
        setSelectedFeatures(features.map(feature => {
            return JSON.stringify(feature)
        }))
    }

    const dedupeStringArray = (value: string[]): string[] => {
        return value.filter((item, index) => value.indexOf(item) === index)
    }

    const handleFeatureChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value
        if (typeof value === 'string') {
            const obj:IFeature = JSON.parse(value)
            delete obj.associatedProperties
            setSelectedFeatures([JSON.stringify(value)])
        } else if (Array.isArray(value)) {
            let output: string[] = []
            value.map(v => {
                const obj:IFeature = JSON.parse(v)
                delete obj.associatedProperties
                output.push(JSON.stringify(obj))
            })
            setSelectedFeatures(dedupeStringArray(output))
        }
        const output = toFeatureArray(value)
        setFeatures(output)
    }

    const renderSelectedFeature = (value: string[]) => {
        const items: IFeature[] = toFeatureArray(value)
        return (
            <div title={items.map(item => item.name).join(', ')} className='multiselect_checkbox_menu_selection_value'>
                {items.map(item => item.name).join(', ')}
            </div>
        )
    }


    /***
     * RENDER PROPS
     */
    const itemXs:number = 1
    const itemSm:number = 1
    const itemMd:number = 2
    const itemLg:number = 2
    const MENU_ITEM_HEIGHT = 48;
    const MENU_ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
            },
        },
    }

    return (
        <div id={id} className='page_container' ref={ref}>
            <Box component='form' noValidate autoComplete='off' className='property_editor' style={{height: containerHeight}}>
                <Grid container spacing={{ xs: 3, sm: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 4, lg: 6 }}>
                    <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                        <TextField
                            required
                            error={propertyNameError}
                            className='textInput'
                            label='Property Name'
                            value={propertyName}
                            inputProps={{ maxLength: 50 }}
                            variant='filled'
                            onChange={handlePropertyNameChange}
                        />
                    </Grid>
                    <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                        <FormControl variant='filled' error={propertyOwnerError} className='textInput' required>
                            <InputLabel id='ownerLabel'>Owner</InputLabel>
                            <Select
                                id='owner'
                                labelId='ownerLabel'
                                value={[JSON.stringify(propertyOwner)]}
                                onChange={handleOwnerChange}
                                renderValue={renderSelectedContact}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key={DEFAULT_CONTACT.id} value={JSON.stringify(DEFAULT_CONTACT)}>{SELECT_ONE}</MenuItem>
                                {
                                    contacts?.map((contact) =>
                                        <MenuItem key={contact.id} value={JSON.stringify(contact)}>
                                            <div className='selectOne_contact_menu_item'>
                                                <div className='label'>{contact.name}</div>
                                                {(contact.phone1 || contact.phone2) && (
                                                    <span className='subLabel'>
                                                        <strong>Phone: </strong>
                                                        {`${contact.phone1 || contact.phone2}`}
                                                    </span>
                                                )}
                                                {(contact.email1 || contact.email2) && (
                                                    <span className='subLabel'>
                                                        <strong>Email: </strong>
                                                        {`${contact.email1 || contact.email2}`}
                                                    </span>
                                                )}
                                            </div>
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                        <TextField
                            required
                            error={locationError}
                            className='textInput'
                            label='Location'
                            value={location.id}
                            variant='filled'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleLocationChange(event.target.value)
                                setLocationError(false)
                            }}
                            select
                        >
                            <MenuItem key={DEFAULT_LOCATION.id} value={DEFAULT_LOCATION.id}>{SELECT_ONE}</MenuItem>
                            {
                                locations?.map((loc) =>
                                    <MenuItem key={loc.id} value={loc.id}>{loc.name}{loc.island && `, ${reversedEnum(Island)[loc.island as Island]}`}</MenuItem>
                                )
                            }
                        </TextField>
                    </Grid>
                    {(isResidential || isCommercialRental) && (
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <div className='compositeWrapper'>
                                {isResidential && (
                                    <TextField
                                        required
                                        error={bedroomsError}
                                        className='textInput composite3'
                                        label='Bedrooms'
                                        value={bedrooms}
                                        type='number'
                                        variant='filled'
                                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2, step: '1', min: 1, max: 10}}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (event.target.value.toString().length <= 2) {
                                                const val = toNumber(event.target.value, bedrooms)
                                                setBedrooms(val > 0 ? val : 1)
                                                setBedroomsError(false)
                                            }
                                        }}
                                    />
                                )}
                                <TextField
                                    required
                                    error={bathroomsError}
                                    className={`textInput ${isResidential ? 'composite3' : 'composite2'}`}
                                    label='Bathrooms'
                                    value={bathrooms}
                                    type='number'
                                    variant='filled'
                                    inputProps={{maxLength: 3, step: '0.5', min: 1, max: 100}}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event.target.value.toString().length <= 4) {
                                            const val = toNumber(event.target.value, bathrooms)
                                            setBathrooms(val > 0 ? val : 1)
                                            setBathroomsError(false)
                                        }
                                    }}
                                />
                                <TextField
                                    required
                                    error={floorsError}
                                    className={`textInput ${isResidential ? 'composite3' : 'composite2'}`}
                                    label='Floors'
                                    value={floors}
                                    type='number'
                                    variant='filled'
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2, step: '1', min: 1, max: 20}}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event.target.value.toString().length <= 2) {
                                            const val = toNumber(event.target.value, floors)
                                            setFloors(val > 0 ? val : 1)
                                            setFloorsError(false)
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                    )}
                    {isSale && (
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <div className='compositeWrapper'>
                                <TextField
                                    className={`textInput ${isResidential ? 'composite2' : ''}`}
                                    label='Acreage'
                                    value={acreage}
                                    type='number'
                                    variant='filled'
                                    inputProps={{inputMode: 'numeric', step: '0.1', min: 0.1, max: 100}}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const acreageVal: number = toNumber(event.target.value, acreage)
                                        let maxAcreage: number = 100
                                        if (acreageVal <= maxAcreage) {
                                            setAcreage(acreageVal)
                                        }
                                    }}
                                />
                                {isResidential && (
                                    <TextField
                                        required
                                        error={priceError}
                                        id='residential_sale_price'
                                        className='textInput composite2'
                                        label='Price'
                                        value={price}
                                        type='number'
                                        variant='filled'
                                        inputProps={{inputMode: 'numeric', step: price >= 100 ? '100' : '1', min: 0}}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const priceWithinRange: boolean = isPriceWithinRange(event.target.value)
                                            if (priceWithinRange) {
                                                const val = toNumber(event.target.value, price)
                                                setPrice(val > 0 ? val : 1)
                                                setPriceError(false)
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </Grid>
                    )}
                    {(isResidentialRental || isCommercial || isLand) && (
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <div className='compositeWrapper'>
                                <TextField
                                    required
                                    error={priceError}
                                    id='residential_commercial_land_price'
                                    className={`textInput ${(isResidential || isCommercialRental) ? 'composite2' : ((isCommercialSale || isLandSale) ? 'composite1' : 'composite3')}`}
                                    label='Price'
                                    value={price}
                                    type='number'
                                    variant='filled'
                                    inputProps={{inputMode: 'numeric', step: price >= 100 ? '100' : '1', min: 0}}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const priceWithinRange: boolean = isPriceWithinRange(event.target.value)
                                        if (priceWithinRange) {
                                            const val = toNumber(event.target.value, price)
                                            setPrice(val > 0 ? val : 1)
                                            setPriceError(false)
                                        }
                                    }}
                                />
                                {(isResidential || isCommercialRental) && (
                                    <TextField
                                        label='Frequency'
                                        value={priceFreq}
                                        className='textInput composite2'
                                        variant='filled'
                                        select
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPriceFreq(event.target.value)}}
                                    >
                                        {
                                            Object.keys(PriceFreq).map(key => {
                                                return <MenuItem key={key} value={key}>{PriceFreq[key as keyof typeof PriceFreq]}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                )}
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                        <div className='compositeWrapper'>
                            <TextField
                                className='textInput composite2'
                                label='Latitude'
                                value={latitude}
                                type='number'
                                inputProps={{step: '0.0001'}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton edge='end' onClick={() => {
                                                setShowMap(!showMap)
                                            }}>
                                                <FontAwesomeIcon icon={faLocationDot} title='Toggle Coordinates Editor' className='map_edit_icon' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                variant='filled'
                                onChange={handleLatitudeChange}
                            />
                            <TextField
                                className='textInput composite2'
                                label='Longitude'
                                value={longitude}
                                type='number'
                                inputProps={{step: '0.0001'}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton edge='end' onClick={() => {
                                                setShowMap(!showMap)
                                            }}>
                                                <FontAwesomeIcon icon={faLocationDot} title='Toggle Coordinates Editor' className='map_edit_icon' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                variant='filled'
                                onChange={handleLongitudeChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                        <div>
                            {(! isLandSale(property.propertyType)) && (
                                <>
                                    <FormControl variant='filled' className='textInput compositeVertical'>
                                        <InputLabel id='featuresLabel'>Features</InputLabel>
                                        <Select
                                            id='features'
                                            labelId='featuresLabel'
                                            value={selectedFeatures}
                                            onChange={handleFeatureChange}
                                            renderValue={renderSelectedFeature}
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {featureSelection?.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={JSON.stringify(item)}>
                                                        <Checkbox checked={features.some((feature) => feature.id === item.id)} />
                                                        <ListItemText primary={item.name}/>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        label='Furnished Type'
                                        value={furnishedType}
                                        className='textInput compositeVertical'
                                        variant='filled'
                                        select
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setFurnishedType(event.target.value)}}
                                    >
                                        {
                                            Object.keys(FurnishedType).map(key => {
                                                return <MenuItem key={key} value={key}>{FurnishedType[key as keyof typeof FurnishedType]}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </>
                            )}

                            <TextField
                                required
                                error={captionError}
                                className='textInput compositeVertical'
                                label='Caption'
                                value={caption}
                                inputProps={{ maxLength: 500 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCaption(event.target.value)
                                    setCaptionError(false)
                                }}
                            />
                            <TextField
                                className='textInput compositeVertical'
                                label='Description'
                                value={description}
                                inputProps={{ maxLength: 9000 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setDescription(event.target.value)}}
                                multiline
                                rows={isLand ? 17.5 : 11}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={itemXs} sm={itemSm} md={4} lg={4}>
                        <div className={`property_map ${!showMap ? 'dashed_border' : ''}`}>
                            {showMap && coordinates ? (
                                <>
                                    <SecondaryButton label='Hide Map' startIcon={<FontAwesomeIcon icon={faCircleXmark} />} onClick={() => setShowMap(false)} className='hide-map-button'></SecondaryButton>
                                    <Map label={propertyName} coordinates={coordinates} zoomLevel={14} applySelectedCoordinates={applySelectedCoordinates} />
                                </>
                            ) : (
                                <div className='empty_state' onClick={() => setShowMap(true)}>
                                    <FontAwesomeIcon icon={faMapLocationDot} className='icon' />
                                    <div className='instructions'>Click here to view the map and edit the coordinates of {propertyName}</div>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>

            </Box>
            <div className='property_editor_buttons'>
                <SecondaryButton id={`${id}_back_button`} label='Back' startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />} onClick={handleBack}  />
                <PrimaryButton id={`${id}_save_button`} label='Save' startIcon={<FontAwesomeIcon icon={faSave} />} onClick={_handleSave} />
            </div>
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </div>
    )
}
