import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {IFeature} from 'types'
import {apiDelete, apiGet, apiPost} from 'functions/ApiUtils'
import {growl} from 'functions/CommonUtils'

export const apiListFeatures = (    accessToken: string|undefined,
                                    setData: Function,
                                    setLoaded: Function,
                                    associationFilter?: boolean | undefined,
                                    forTable?: boolean) => {
    apiGet(accessToken, `/features${associationFilter !== undefined ? `?associated=${associationFilter}` : ''}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            let features: IFeature[] = response.data as unknown as IFeature[]
            if (forTable === undefined || !forTable) {
                features.map(feature => {
                    delete feature.associatedProperties
                })
            }
            setData(features)
            setTimeout(() => {setLoaded(true)}, 300)
        })
}

export const apiUpsertFeature = (   accessToken: string|undefined,
                                    setData: Function,
                                    data: IFeature[],
                                    payload: any,
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiPost(accessToken, '/features', payload)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            const upsertedFeature: IFeature = response.data as unknown as IFeature
            const dataCopy = [...data]
            let found = false
            dataCopy.forEach((feature: IFeature) => {
                if (feature.id === upsertedFeature.id) {
                    found = true
                }
            })
            if (!found) {
                setData([upsertedFeature, ...dataCopy])
            } else {
                setData(dataCopy)
            }
            growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        })
}

export const apiDeleteFeature = (   accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: IFeature[],
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiDelete(accessToken, `/features/${id}`)
        .then(response => {
            if (response?.data === true) {
                const dataCopy = [...data].filter(feature => feature.id !== id)
                setData(dataCopy)
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Could not remove feature ${id}`
                growl(msg, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}
