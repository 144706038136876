import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {GridActionsCellItem, GridRowId, GridRowParams} from '@mui/x-data-grid'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPen,
    faTrash,
    faAddressBook,
} from '@fortawesome/free-solid-svg-icons'

//-- TABLE AND TABLE DEFINITIONS
import {Table} from 'components/table/Table'
import {contactsColumnDef} from 'metadata/TableDefinitions'

//-- PAGE COMPONENTS
import {CommonFilter} from 'components/commonFilter/CommonFilter'
import {PrimaryButton} from 'components/button/Button'
import {Growl} from 'components/growl/Growl'
import {LoadingState} from 'components/loadingState/LoadingState'
import {EmptyState} from 'components/emptyState/EmptyState'

//-- SUB PAGES
import {Contact} from './Contact'

//-- UTILITIES AND TYPES
import {applyView, getViewId, growl, randomNumber} from 'functions/CommonUtils'
import {GrowlType, IContact} from 'types'

//-- API AND ACTIONS
import {
    apiDeleteContact,
    apiListContacts,
} from 'api'

//-- STYLES
import './Contacts.scss'


/***
 * LOCAL CONSTANTS
 */
const EDIT_VIEW_ID= 'contact'
const NEW_VIEW_ID= 'newContact'


/***
 * INTERFACES
 */
interface IContactsProps {
    id?: string,
    setPageSubTitle: Function
}


/**
 * Functional Component - Contacts
 * @param id
 * @param setPageSubTitle
 * @constructor
 */
export const Contacts: React.FC<IContactsProps> = ({
    id = 'contactsPage',
    setPageSubTitle,
}: IContactsProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [contactId, setContactId] = useState<number|string|undefined>(getViewId(EDIT_VIEW_ID))
    const [data, setData] = useState<IContact[]>([])
    const [loaded, setLoaded] = useState(false)
    const [contact, setContact] = useState<IContact|undefined>()
    const [associationFilter, setAssociationFilter] = useState<boolean>()
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        setLoaded(false)
        if (accessToken) {
            if (associationFilter !== undefined) {
                apiListContacts(accessToken, setData, setLoaded, associationFilter)
            } else {
                apiListContacts(accessToken, setData, setLoaded)
            }
        }
    }, [associationFilter, accessToken])

    useEffect(() => {
        if (getViewId(NEW_VIEW_ID)) {
            initNewContact()
        }
    }, [getViewId(NEW_VIEW_ID)])

    useEffect(() => {
        if (data && data.length) {
            if (contactId) {
                const value: IContact | undefined = data.find((contact) => contact.id === contactId)
                setContact(value)
                setPageSubTitle(`Contact Editor`)
            }
        }
    }, [contactId, data])


    /***
     * UTILITY FUNCTIONS
     */
    const initNewContact = () => {
        const initContact: IContact = {
            name: '',
            email1: '',
            email2: '',
            phone1: '',
            phone2: '',
        }
        setContact(initContact)
        applyView(NEW_VIEW_ID,'true')
        setPageSubTitle(`New Contact`)
    }

    const handleResetEditor = () => {
        setContact(undefined)
        setContactId(undefined)
        setPageSubTitle(undefined)
        applyView(EDIT_VIEW_ID, undefined)
        applyView(NEW_VIEW_ID,undefined)
    }

    const handleSave = () => {
        growl(`${contact ? contact.name : 'Contact'} Saved Successfully`, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        handleResetEditor()
    }


    /***
     * CALLBACK FUNCTIONS
     */
    const editContact = React.useCallback(
        (contact: IContact) => () => {
            if (contact?.id) {
                setContactId(contact.id)
                setContact(contact)
                applyView(EDIT_VIEW_ID, contact?.id)
                applyView(NEW_VIEW_ID, undefined)
            } else {
                setTimeout(() => {
                    growl(`Unable to edit selected contact`, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
                }, 100)
            }
        },
        [data],
    )

    const deleteContact = React.useCallback(
        (id: GridRowId) => () => {
            apiDeleteContact(accessToken, id, setData, data, 'Contact Deleted', setGrowlMessage, setGrowlType, setGrowlInstance)
        },
        [data],
    )


    /***
     * ICONS
     */
    const editActionIcon = (contactName: string) => <FontAwesomeIcon icon={faPen} title={`Edit ${contactName}`} className='datagrid-action-icon' />
    const deleteActionIcon = (contactName: string) => <FontAwesomeIcon icon={faTrash} title={`Delete ${contactName}`} className='datagrid-action-icon' />


    /***
     * DATAGRID COLUMNS
     */
    const columns= [...contactsColumnDef,
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                    icon={editActionIcon(params.row.name)}
                    label='Edit'
                    onClick={editContact(params.row)}
                />,
                <GridActionsCellItem
                    icon={deleteActionIcon(params.row.name)}
                    label='Delete'
                    disabled={params.row.associatedProperties > 0}
                    onClick={deleteContact(params.id)}
                />,
            ]
        }
    ]

    return(
        <>
            {loaded  ? (
                <>
                    {contact ? (
                        <>
                            <Contact    id='contactPage' contact={contact} setData={setData} data={data}
                                        handleBack={handleResetEditor} handleSave={handleSave} />
                        </>
                    ) : contactId === undefined && (
                        <>
                            {data?.length ? (
                                <>
                                    <div id={`${id}_header`} className='base_header'>
                                        <CommonFilter   id={`${id}_contactsFilter`} title='Filter Contacts'
                                                        associationFilter={associationFilter} setAssociationFilter={setAssociationFilter}
                                        />
                                        <div className='controls'>
                                            <PrimaryButton  id={`${id}_new`} onClick={() => initNewContact()}
                                                            startIcon={<FontAwesomeIcon icon={faAddressBook} />}
                                                            label='New Contact' />
                                        </div>
                                    </div>
                                    <Table id={`${id}_contacts`} columns={columns} rows={data} />
                                </>
                            ) : (
                                <EmptyState id={'emptyState'} icon={faAddressBook}
                                            caption={'No contacts found'}
                                            text={'Click the button below to add a new property owner or client contact.'}
                                            action={initNewContact}
                                            actionLabel={'Add New Contact'}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <LoadingState id={`${id}_loading`}/>
            )}
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    )
}