import React, {useEffect, useRef, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faTrash,
    faVideo,
} from '@fortawesome/free-solid-svg-icons'

//-- OTHER THIRD PARTY LIBRARIES
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import {Growl} from 'components/growl/Growl'
import {growl, randomNumber} from 'functions/CommonUtils'

//-- UTILITIES AND TYPES
import {
    IProperty,
    IVideo, GrowlType,
} from "types"

//-- API AND ACTIONS
import {
    apiListPropertyVideos,
    apiPropertyVideoUpload,
    apiRemovePropertyVideo,
} from 'api'

//-- STYLES
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css'
import 'filepond/dist/filepond.min.css'
import './Videos.scss'


/***
 * PLUGIN REGISTRATION
 */
registerPlugin(FilePondPluginMediaPreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


/***
 * INTERFACES
 */
interface IVideosProps {
    id?: string,
    property: IProperty,
    handleBack: Function,
}


/**
 * Functional Component - Videos
 * @param id
 * @param property
 * @param handleBack
 * @constructor
 */
export const Videos: React.FC<IVideosProps> = ({    id = 'videosPage',
                                                    property,
                                                    handleBack,
                                                }: IVideosProps) => {

    //-- Local References
    const pondRef = useRef<FilePond>(null)

    //-- Local State
    const [accessToken] = useAccessToken()
    const [videos, setVideos] = useState<IVideo[]>([])
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        if (accessToken) {
            apiListPropertyVideos(accessToken, property.id!, setVideos)
        }
    }, [accessToken])

    useEffect(() => {
        if (videos) {
            property.videos = videos
        }
    }, [videos])


    /***
     * UTILITY FUNCTIONS
     */
    const onUploadComplete = () => {
        if (accessToken) {
            apiListPropertyVideos(accessToken, property.id!, setVideos)
            growl('Successfully Uploaded Videos', 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        } else {
            growl('Unable to Upload Videos', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
        }
    }

    return (
        <>
            <div id={id} className='videoPage_container'>
                <Scrollbars className='property_video_scroller'>
                    <div id={`${id}_propertyVideos`} className='property_videos'>
                        {videos && videos.length ? videos.map(video => (
                            <div className='video_frame' key={video.id}>
                                <div className='video_mask'>
                                    <video controls>
                                        <source src={`data:image/jpeg;base64,${video.data}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <PrimaryButton id={`${id}_remove_video_btn`} label='Remove Video' startIcon={<FontAwesomeIcon icon={faTrash} />} onClick={() => apiRemovePropertyVideo(accessToken, property.id!, video.id, setVideos)} />
                            </div>
                        )) : (
                            <div className={'no_videos'}>
                                <FontAwesomeIcon icon={faVideo} />
                                <div>Video Not Available</div>
                            </div>
                        )}
                    </div>
                </Scrollbars>
                <FilePond
                    ref={pondRef}
                    credits={false}
                    allowMultiple={false}
                    allowRevert={false}
                    dropValidation={true}
                    allowFileTypeValidation={true}
                    acceptedFileTypes={['video/mp4']}
                    maxFileSize='40000000'
                    onprocessfiles={() => {
                        if (pondRef && pondRef.current) {
                            const ref = pondRef.current
                            ref.removeFiles()
                        }
                        onUploadComplete()
                    }}
                    server={property.id ? apiPropertyVideoUpload(accessToken, +property.id) : null}
                    labelIdle='Drag property video here or <span class="generic_link">upload a file</span>'
                />
            </div>

            <div className='property_video_editor_buttons'>
                <SecondaryButton id={`${id}_back_button`} label='Back' startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />} onClick={handleBack}  />
            </div>

            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    );
}