import React, {useEffect, useRef, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faStar,
    faCircleXmark,
    faPanorama,
} from '@fortawesome/free-solid-svg-icons'

//-- OTHER THIRD PARTY LIBRARIES
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

//-- PAGE COMPONENTS
import {SecondaryButton} from 'components/button/Button'
import {Growl} from 'components/growl/Growl'
import {growl, randomNumber} from 'functions/CommonUtils'

//-- UTILITIES AND TYPES
import {
    IProperty,
    IPhoto, GrowlType,
} from "types"

//-- API AND ACTIONS
import {
    apiListPropertyPhotos,
    apiPropertyPhotoUpload,
    apiUpdatePropertyPhoto,
    apiRemovePropertyPhoto,
} from 'api'

//-- STYLES
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import './Photos.scss'


/***
 * PLUGIN REGISTRATION
 */
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


/***
 * INTERFACES
 */
interface IPhotosProps {
    id?: string,
    property: IProperty,
    handleBack: Function,
}


/**
 * Functional Component - Photos
 * @param id
 * @param property
 * @param handleBack
 * @constructor
 */
export const Photos: React.FC<IPhotosProps> = ({
    id = 'photosPage',
    property,
    handleBack,
}: IPhotosProps) => {

    //-- Local References
    const pondRef = useRef<FilePond>(null)

    //-- Local State
    const [accessToken] = useAccessToken()
    const [photos, setPhotos] = useState<IPhoto[]>([])
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        if (accessToken) {
            apiListPropertyPhotos(accessToken, property.id!, setPhotos)
        }
    }, [accessToken])

    useEffect(() => {
        if (photos) {
            property.photos = photos
        }
    }, [photos])


    /***
     * UTILITY FUNCTIONS
     */
    const onUploadComplete = () => {
        if (accessToken) {
            apiListPropertyPhotos(accessToken, property.id!, setPhotos)
            growl('Successfully Uploaded Photos', 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        } else {
            growl('Unable to Upload Photos', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
        }
    }

    return (
        <>
            <div id={id} className='photoPage_container'>
                <Scrollbars className='property_photo_scroller'>
                    <div id={`${id}_propertyPhotos`} className='property_photos'>
                        {photos && photos.length ? photos.map(photo => (
                            <div className='photo_frame' key={photo.id}>
                                <div className='photo_controls'>
                                    <div className={`photo_control feature${photo.featured ? '-enabled' : '-disabled'}`}
                                         title={`${photo.featured ? 'Featured Photo' : 'Use as Featured Photo'}`}
                                         onClick={() => !photo.featured && apiUpdatePropertyPhoto(accessToken, property.id!, photo.id, {featured: true}, setPhotos)}
                                    >
                                        <FontAwesomeIcon icon={faStar} fixedWidth={true} />
                                    </div>
                                    <div className='photo_control'
                                         title='Remove Photo'
                                         onClick={() => apiRemovePropertyPhoto(accessToken, property.id!, photo.id, setPhotos)}
                                    >
                                        <FontAwesomeIcon icon={faCircleXmark} fixedWidth={true} />
                                    </div>
                                </div>
                                <div className='photo_mask' title={photo.order ? `Order: ${photo.order}` : ''}>
                                    <img src={`data:image/jpeg;base64,${photo.data}`} />
                                </div>
                            </div>
                        )) : (
                            <div className={'no_photos'}>
                                <FontAwesomeIcon icon={faPanorama} />
                                <div>No Photos Available</div>
                            </div>
                        )}
                    </div>
                </Scrollbars>
                <FilePond
                    ref={pondRef}
                    credits={false}
                    allowMultiple={true}
                    allowRevert={false}
                    dropValidation={true}
                    allowFileTypeValidation={true}
                    acceptedFileTypes={['image/*']}
                    maxFileSize='512000'
                    onprocessfiles={() => {
                        if (pondRef && pondRef.current) {
                            const ref = pondRef.current
                            ref.removeFiles()
                        }
                        onUploadComplete()
                    }}
                    server={property.id ? apiPropertyPhotoUpload(accessToken, +property.id) : null}
                    labelIdle='Drag property photos here or <span class="generic_link">upload a file</span>'
                />
            </div>

            <div className='property_photo_editor_buttons'>
                <SecondaryButton id={`${id}_back_button`} label='Back' startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />} onClick={handleBack}  />
            </div>

            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    );
}