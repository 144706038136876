import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {IViewing, ViewingStatus} from 'types'
import {apiDelete, apiGet, apiPost} from 'functions/ApiUtils'
import {growl, reversedEnum} from 'functions/CommonUtils'
import moment from 'moment'

export const apiListViewings = (accessToken: string|undefined,
                                setData: Function,
                                setLoaded: Function,
                                statusFilter?: ViewingStatus|undefined) => {
    apiGet(accessToken, `/viewings${statusFilter !== undefined ? `?status=${reversedEnum(ViewingStatus)[statusFilter]}` : ''}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            let viewings: IViewing[] = response.data as unknown as IViewing[]
            setData(viewings)
            setTimeout(() => {setLoaded(true)}, 300)
        })
}

export const apiUpsertViewing = async ( accessToken: string|undefined,
                                        setData: Function,
                                        data: IViewing[],
                                        payload: any,
                                        growlMessage?: string,
                                        setGrowlMessage?: Function,
                                        setGrowlType?: Function,
                                        setGrowlInstance?: Function): Promise<void> => {
    apiPost(accessToken, '/viewings', payload)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            const upsertedViewing: IViewing = response.data as unknown as IViewing
            const dataCopy = [...data]
            let found = false
            dataCopy.forEach((viewing: IViewing) => {
                if (viewing.id === upsertedViewing.id) {
                    viewing.property = upsertedViewing.property
                    viewing.client = upsertedViewing.client
                    viewing.schedule = upsertedViewing.schedule
                    found = true
                }
            })
            if (!found) {
                setData([upsertedViewing, ...dataCopy])
            } else {
                setData(dataCopy)
            }
            if (growlMessage && setGrowlMessage && setGrowlType && setGrowlInstance) {
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}

export const apiDeleteViewing = (   accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: IViewing[],
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiDelete(accessToken, `/viewings/${id}`)
        .then(response => {
            if (response?.data === true) {
                const dataCopy = [...data].filter(viewing => viewing.id !== id)
                setData(dataCopy)
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Could not remove viewing ${id}`
                growl(msg, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}

export const apiNotifyViewing = (   accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: IViewing[],
                                    client: boolean,
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiPost(accessToken, `/viewings/${id}/notify/${client ? 'CLIENT' : 'OWNER'}?timezone=${moment.tz.guess()}`, {})
        .then(response => {
            if (response?.data === true) {
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Error Sending Property Viewing Notification`
                growl(msg, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}
